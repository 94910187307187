/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_UPLOAD_USER_API = `${API_URL}/admin/bulk/employee/add`;
export const GET_USER_LIST_API = `${API_URL}/admin/employee/list`;
export const POST_USER_DELETE_API = `${API_URL}/admin/employee/delete`;
export const POST_USER_BLOCK_UNBLOCK_API = `${API_URL}/admin/employee/block`;


export const OnboaridngActionTypes = {
    post_uploadUser: createRequestActionTypes('POST_UPLOAD_USER'),
    get_userList: createRequestActionTypes('GET_USER_LIST'),
    post_userDelete: createRequestActionTypes('POST_USER_DELETE'),
    post_userBlock: createRequestActionTypes('POST_USER_BLOCK'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};