import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PostAddUserAction, PostUpdateUserAction, GetAllRolesAction } from '../actions/UamActions';
import { renderTextField, renderSelectField } from "../../../../shared/reduxFields";
import validate from './AddEditUamModalValidate';

class AddEditUamModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showUnit: false
        }
    }

    componentDidMount = () => {
        this.props.GetAllRolesAction();
        const userData = this.props.userDetails;
        if (this.props.type === "edit") {
            this.props.dispatch(change('AddEditUamModalForm', 'roleId', userData.role ))
            this.props.dispatch(change('AddEditUamModalForm', 'name', userData.name ))
            this.props.dispatch(change('AddEditUamModalForm', 'email', userData.email ))
            this.props.dispatch(change('AddEditUamModalForm', 'mobile', userData.mobile ))
        }
    }


    componentDidUpdate = (prevProps) => {

        if (this.props.rolesList !== undefined && this.props.rolesList !== null && this.props.rolesList !== prevProps.rolesList) {
            this.setState({
                rolesList: this.props.rolesList
            })
        }
    }

    onFormSubmitHandler = (formData) => {
        let postData = {
            "role": formData.roleId,
            "name": formData.name,
            "mobile": formData.mobile,
        }

        if(this.props.type !== "edit") {
            postData['password'] = formData.confirmPassword;
            postData['email'] = formData.email;
            this.props.PostAddUserAction(postData);
        } else {
            this.props.PostUpdateUserAction(postData, this.props.userDetails.id);
        }
        
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, type, unitList, rolesList } = this.props;
        const { showUnit } = this.state;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>{type} User Role</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="roleId"
                                                name="roleId"
                                                label="Select Role"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    rolesList.length > 0
                                                        ? rolesList.map((obj, index) => {
                                                            return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                        })
                                                        :
                                                        <MenuItem>Please Add Role First</MenuItem>
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="name"
                                                name="name"
                                                label="Name"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 50,
                                                    minLength: 1
                                                }}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="email"
                                                name="email"
                                                label="Email"
                                                required={true}
                                                disabled={this.props.type !== "edit" ? false : true}
                                                inputProps={{
                                                    maxLength: 50,
                                                    minLength: 5
                                                }}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="mobile"
                                                name="mobile"
                                                label="Mobile Number"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 10,
                                                    minLength: 10
                                                }}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        {this.props.type !== "edit" &&
                                            <>
                                                <GridItem xs={12}>
                                                    <Field
                                                        id="password"
                                                        name="password"
                                                        label="Password"
                                                        required={true}
                                                        inputProps={{
                                                            maxLength: 50,
                                                            minLength: 8
                                                        }}
                                                        component={renderTextField}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12}>
                                                    <Field
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        label="Confirm Password"
                                                        required={true}
                                                        inputProps={{
                                                            maxLength: 50,
                                                            minLength: 8
                                                        }}
                                                        component={renderTextField}
                                                    />
                                                </GridItem>
                                            </>
                                        }
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

AddEditUamModal = reduxForm({
    form: "AddEditUamModalForm",
    validate,
    enableReinitialize: true
})(AddEditUamModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails,
        rolesList: state.uamReducer.rolesList !== null && state.uamReducer.rolesList.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostAddUserAction,
            PostUpdateUserAction,
            GetAllRolesAction,
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddEditUamModal));
