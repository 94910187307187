import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';

import { GetUserListAction } from './actions/OnboardingActions';
import DeactivateUserModal from './Modal/DeactivateUserModal';
import DeleteUserModal from './Modal/DeleteUserModal';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            userId: 0,
            userDetails: '',
            openDeactivateUserModal: false,
            openDeleteUserModal: false
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetUserListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.userList !== undefined && this.props.userList !== null && this.props.userList !== prevProps.userList) {
            this.setState({
                data: this.props.userList
            })
        }
    }

    handleEditBrandDetails = (event, rowData) => {
        let userId = rowData.Id;
        this.setState({
            userId: userId,
            openEditUserModal: true
        })
    }

    handleDeleteUser = (event, rowData) => {
        let userId = rowData.id;
        this.setState({
            userId: userId,
            userDetails: rowData,
            openDeleteUserModal: true
        })
    }

    handleDeactivateUser = (event, rowData) => {
        let userId = rowData.id;
        this.setState({
            userId: userId,
            userDetails: rowData,
            openDeactivateUserModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openEditUserModal: false,
            userId: 0,
            userDetails: '',
            openDeactivateUserModal: false,
            openDeleteUserModal: false
        })
    }

    render() {
        const { loading } = this.props;
        const { data, userId, userDetails, openDeactivateUserModal, openDeleteUserModal } = this.state;
        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openDeactivateUserModal && <DeactivateUserModal userId={userId} userDetails={userDetails} handleClose={() => this.handleClose()}/>}
                {openDeleteUserModal && <DeleteUserModal userId={userId} userDetails={userDetails} handleClose={() => this.handleClose()}/>}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>User List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode'},
                                        { title: 'Mobile No', field: 'mobile'},
                                        { title: 'Name', field: 'fullname'},
                                        { title: 'Email', field: 'email'},
                                        { title: 'Gender', field: 'sex'},
                                        { title: 'Address', field: 'addr1'},
                                        { title: 'PAN No', field: 'panno'},
                                        { title: 'PF No', field: 'pfno'},
                                        { title: 'Account', field: 'accountnumber'},
                                        { title: 'Bank', field: 'bankname'},
                                        { title: 'IFSC Code', field: 'ifsccode'},
                                        { title: 'Designation', field: 'designation'},
                                        { title: 'Division', field: 'division'},
                                        { title: 'Department', field: 'department'},
                                        { title: 'Branch Code', field: 'branchcode'},
                                        { title: 'Branch', field: 'branch'},
                                        { title: 'Manager', field: 'manager'},
                                        { 
                                            title: 'DOB', 
                                            field: 'dob',
                                            render: rowData => rowData.dob !== null ? moment.utc(rowData.dob).local().format('DD-MM-YYYY') : "NA",
                                        },
                                        { 
                                            title: 'DOJ', 
                                            field: 'doj',
                                            render: rowData => rowData.doj !== null ? moment.utc(rowData.doj).local().format('DD-MM-YYYY') : "NA",
                                        },
                                        { 
                                            title: 'DOL', 
                                            field: 'dol',
                                            render: rowData => rowData.dol !== null ? moment.utc(rowData.dol).local().format('DD-MM-YYYY') : "NA",
                                        },
                                        { title: 'ESIC No', field: 'esicno'},
                                        { title: 'Ext Field', field: 'extfield'},
                                        { title: 'Grade', field: 'grade'},
                                        { title: 'Status', field: 'isactive'},
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        grouping: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData(),
                                            },
                                            // rowData => ({
                                            //     icon: Edit,
                                            //     tooltip: 'Edit User Details',
                                            //     onClick: (event, rowData) => this.handleEditBrandDetails(event, rowData)
                                            // }),
                                            rowData => ({
                                                icon: BlockIcon,
                                                tooltip: rowData.isactive == "Y" ? 'Deactivate User' : 'Activate User',
                                                disabled: false,
                                                onClick: (event, rowData) => this.handleDeactivateUser(event, rowData)
                                            }),
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete user',
                                                disabled: rowData.isactive == "Y" ? true : false,
                                                onClick: (event, rowData) => this.handleDeleteUser(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

UserList = reduxForm({
    form: 'UserListForm',
    enableReinitialize: true
})(UserList)

function mapStateToProps(state) {
    return {
        loading: state.onboardingReducer.loading,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails,
        userList: state.onboardingReducer.userList !== null && state.onboardingReducer.userList.data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetUserListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserList))
