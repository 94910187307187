import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
    let redirectPath = '/admin/dashboard';

    if (
        user.toUpperCase() === "ADMIN" ||
        user.toUpperCase() === "CHECKER" || 
        user.toUpperCase() === "MAKER" ||
        user.toUpperCase() === "SUPPORT"
    ) {
        redirectPath = '/admin/dashboard';
    } 

    return (
        <div>
            <Route
                {...rest}
                render={(props) =>
                    user
                        ? <Component user={user} {...props} />
                        : <Redirect to={{ pathname: redirectPath }} />
                }
            />
        </div>
    )
}

export default PrivateRoute;