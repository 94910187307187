import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

import { withStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { renderTextField } from "../../../shared/reduxFields";
import { LoginUserAction } from '../actions/LoginActions';
import validate from './forgotPasswordValidate';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onFormSubmitHandler = (formData) => {
        // let postData = {
        //     "username": formData.username,
        //     "password": formData.password
        // }
        // this.props.LoginUserAction(postData)
    }

    render() {
        const { classes, handleSubmit } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                            <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>Change Password</h6>
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <Field
                                        id="emailid"
                                        name="emailid"
                                        label="Email ID"
                                        required={true}
                                        component={renderTextField}
                                    />
                                    <Button color="rose" simple className={classes.sendOtp}>
                                        SEND OTP
                                    </Button>
                                    <Field
                                        id="enterOTp"
                                        name="otp"
                                        label="OTP"
                                        required={true}
                                        component={renderTextField}
                                    />
                                    <Field
                                        id="newPassword"
                                        name="newPassword"
                                        label="New Password"
                                        required={true}
                                        component={renderTextField}
                                    />
                                    <Field
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        required={true}
                                        component={renderTextField}
                                    />
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple onClick={() => this.props.handleClose()} color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </Dialog>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

ForgotPassword = reduxForm({
    form: "ForgotPasswordForm",
    validate,
    enableReinitialize: true
})(ForgotPassword);


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            LoginUserAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ForgotPassword));
