import { MasterCTCActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedMasterCTCFile: null,
    masterCTCList: null,
    masterCTCDelete: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case MasterCTCActionTypes.post_uploadMasterCTC.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MasterCTCActionTypes.post_uploadMasterCTC.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedMasterCTCFile: payload,
            };
        case MasterCTCActionTypes.post_uploadMasterCTC.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case MasterCTCActionTypes.post_masterCTCList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MasterCTCActionTypes.post_masterCTCList.SUCCESS:
            return {
                ...state,
                loading: false,
                masterCTCList: payload,
            };
        case MasterCTCActionTypes.post_masterCTCList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case MasterCTCActionTypes.post_masterCTCDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MasterCTCActionTypes.post_masterCTCDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                masterCTCDelete: payload,
            };
        case MasterCTCActionTypes.post_masterCTCDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case MasterCTCActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
