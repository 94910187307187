
// @material-ui/icons
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import StoreIcon from '@material-ui/icons/Store';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import StyleIcon from '@material-ui/icons/Style';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CompareIcon from '@material-ui/icons/Compare';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CropIcon from '@material-ui/icons/Crop';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import DoneIcon from '@material-ui/icons/Done';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import Dashboard from '../../views/Modules/Dashboard/Dashboard';

import ManageUser  from '../../views/Modules/UAM/ManageUser';
import BulkUserUpload from 'views/Modules/Onboarding/BulkUserUpload';
import UserList from 'views/Modules/Onboarding/UserList';

import BannerUpload from '../../views/Modules/Banner/BannerUpload';
import BannerList from '../../views/Modules/Banner/BannerList';
import BulkAppraisalUpload from 'views/Modules/Appraisal/BulkAppraisalUpload';
import AppraisalList from '../../views/Modules/Appraisal/AppraisalList';

import PayslipUpload from '../../views/Modules/Payslip/PayslipUpload';
import PayslipList from '../../views/Modules/Payslip/PayslipList';

import MasterCTCUpload from '../../views/Modules/MasterCTC/MasterCTCUpload';
import MasterCTCList from '../../views/Modules/MasterCTC/MasterCTCList';

import AttendanceUpload from '../../views/Modules/Attendance/AttendanceUpload';
import AttendanceList from '../../views/Modules/Attendance/AttendanceList';

import SupportList from '../../views/Modules/Support/SupportList';
import EpfList from '../../views/Modules/EPF/EPFList';

var adminRoutes = [
    {
        collapse: true,
        name: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                mini: "DSB",
                component: Dashboard,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Onboarding",
        icon: PeopleAltIcon,
        state: "onboardingCollapse",
        views: [
            {
                path: "/useronboarding",
                name: "User Onboarding",
                mini: "UOB",
                component: BulkUserUpload,
                layout: "/admin"
            },
            {
                path: "/userlist",
                name: "User List",
                mini: "UL",
                component: UserList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Master CTC",
        icon: AccountBalanceIcon,
        state: "masterCtcCollapse",
        views: [
            {
                path: "/mctcupload",
                name: "Master CTC Upload",
                mini: "MCP",
                component: MasterCTCUpload,
                layout: "/admin"
            },
            {
                path: "/mctclist",
                name: "Master CTC List",
                mini: "MCL",
                component: MasterCTCList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Payslip",
        icon: ReceiptIcon,
        state: "payslipCollapse",
        views: [
            {
                path: "/payslipupload",
                name: "Payslip Upload",
                mini: "PSU",
                component: PayslipUpload,
                layout: "/admin"
            },
            {
                path: "/paysliplist",
                name: "Payslip List",
                mini: "PL",
                component: PayslipList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Attendance",
        icon: DateRangeIcon,
        state: "attendanceCollapse",
        views: [
            {
                path: "/attendanceupload",
                name: "Attendance Upload",
                mini: "PSU",
                component: AttendanceUpload,
                layout: "/admin"
            },
            {
                path: "/attendancelist",
                name: "Attendance List",
                mini: "PL",
                component: AttendanceList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Appraisal",
        icon: LocalPrintshopIcon,
        state: "appraisalCollapse",
        views: [
            {
                path: "/appraisalUpload",
                name: "Upload Appraisal",
                mini: "UA",
                component: BulkAppraisalUpload,
                layout: "/admin"
            },
            {
                path: "/appraisalList",
                name: "Appraisal List",
                mini: "AL",
                component: AppraisalList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Banner",
        icon: BrandingWatermarkIcon,
        state: "bannerCollapse",
        views: [
            {
                path: "/bannerupload",
                name: "Upload Banner",
                mini: "UOB",
                component: BannerUpload,
                layout: "/admin"
            },
            {
                path: "/bannerList",
                name: "Banner List",
                mini: "UL",
                component: BannerList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "EPF",
        icon: AccountBalanceWalletIcon,
        state: "epfCollapse",
        views: [
            {
                path: "/epflist",
                name: "EPF List",
                mini: "EL",
                component: EpfList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Support",
        icon: ContactMailIcon,
        state: "supportCollapse",
        views: [
            {
                path: "/support",
                name: "Support List",
                mini: "SPL",
                component: SupportList,
                layout: "/admin"
            }
        ]
    },
    {
        collapse: true,
        name: "Manage Roles",
        icon: PeopleAltIcon,
        state: "uramCollapse",
        views: [
            {
                path: "/roleaccess",
                name: "User Role Access",
                mini: "URA",
                component: ManageUser,
                layout: "/admin"
            },
        ]
    },
    
];
export default adminRoutes;
