/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_EPF_API = `${API_URL}/admin/video_banner/add`;
export const POST_UPDATE_EPF_API = `${API_URL}/admin/video_banner/update`;
export const GET_EPF_LIST_API = `${API_URL}/admin/video_banner/list`;
export const GET_EPF_DETAIL_API = `${API_URL}/admin/video_banner/detail`;
export const POST_EPF_DELETE_API = `${API_URL}/admin/video_banner/delete`;

export const EPFActionTypes = {
    post_createEPF: createRequestActionTypes('POST_CREATE_EPF'),
    post_updateEPF: createRequestActionTypes('POST_UPDATE_EPF'),
    get_epfList: createRequestActionTypes('GET_EPF_LIST'),
    get_epfDetail: createRequestActionTypes('GET_EPF_DETAIL'),
    post_epfDelete: createRequestActionTypes('POST_EPF_DELETE'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};