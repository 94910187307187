const validate = (values) => {
    const errors = {};
    if (!values.month) {
        errors.month = 'Month Required';
    }
    if (!values.year) {
        errors.year = 'Year Required';
    }
    if (!values.type) {
        errors.type = 'Support Type Required';
    }
    return errors;
};
export default validate;