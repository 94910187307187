import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PostEPFUpdateAction, PostCreateEPFAction } from '../actions/EPFActions';
import { renderTextField, renderSelectField } from "../../../../shared/reduxFields";
import validate from './CreateEditEPFModalValidate';

const orderValues = [
    { Key: 1, value: 1},
    { Key: 2, value: 2},
    { Key: 3, value: 3},
    { Key: 4, value: 4},
    { Key: 5, value: 5},
    { Key: 6, value: 6},
    { Key: 7, value: 7},
    { Key: 8, value: 8},
]

const status = [
    { key: 'Active', value: 'Y' },
    { key: 'In-Active', value: 'N' }
]

class CreateEditEpfModal extends Component {

    componentDidMount = () => {
        if(this.props.type !== "Add") {
            this.props.dispatch(change('CreateEditEpfModalForm', 'epfText', this.props.epfDetails.text));
            this.props.dispatch(change('CreateEditEpfModalForm', 'orderId', this.props.epfDetails.sortorder));
            this.props.dispatch(change('CreateEditEpfModalForm', 'redirectURL', this.props.epfDetails.link));
            this.props.dispatch(change('CreateEditEpfModalForm', 'status', this.props.epfDetails.isactive));
        }
    }

    onFormSubmitHandler = (formData) => {
        const epfDetails = this.props.epfDetails
        const epfId = epfDetails.id
        const postData = {
            "text": formData.epfText,
            "sortorder": formData.orderId,
            "link": formData.redirectURL,
            "isactive": this.props.type === "Add" ? "N" : formData.status
        }
        if(this.props.type === 'Add') {
            this.props.PostCreateEPFAction(postData);
        } else {
            this.props.PostEPFUpdateAction(postData, epfId);
        }
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, type } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>{type === 'Add' ? 'Add' : 'Edit'} EPF Order</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="epfText"
                                                name="epfText"
                                                label="Enter Title"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="orderId"
                                                name="orderId"
                                                label="Select Order"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    orderValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.value}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        {this.props.type !== "Add" &&
                                            <GridItem xs={12}>
                                                <Field
                                                    id="status"
                                                    name="status"
                                                    label="Select Status"
                                                    required={true}
                                                    component={renderSelectField}
                                                >
                                                    {
                                                        status.map((obj, index) => {
                                                            return <MenuItem value={obj.value} key={index}>{obj.key}</MenuItem>
                                                        })
                                                    }
                                                </Field>
                                            </GridItem>
                                        }
                                        <GridItem xs={12}>
                                            <Field
                                                id="redirectURL"
                                                name="redirectURL"
                                                label="Redirect URL"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

CreateEditEpfModal = reduxForm({
    form: "CreateEditEpfModalForm",
    validate,
    enableReinitialize: true
})(CreateEditEpfModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostEPFUpdateAction,
            PostCreateEPFAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CreateEditEpfModal));
