import { passwordRegExp } from '../../../shared/utility';

const validate = (values) => {
    const errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = 'Current Password Required';
    }

    if(!values.newPassword) {
        errors.newPassword = "New Password Required";
    }

    if (values.newPassword && !passwordRegExp.test(values.newPassword)) {
        errors.newPassword = "Password Required";
    }

    if(!values.confirmPassword) {
        errors.confirmPassword = "Confirm Password Required";
    }

    if(values.confirmPassword && (values.confirmPassword !== values.newPassword) ) {
        errors.confirmPassword = "Confirm Password Mismatched";
    }

    return errors;
};
export default validate;