import { SupportActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    globalSupportList: null,
    localSupportList: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SupportActionTypes.post_globalSupportList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SupportActionTypes.post_globalSupportList.SUCCESS:
            return {
                ...state,
                loading: false,
                globalSupportList: payload,
            };
        case SupportActionTypes.post_globalSupportList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SupportActionTypes.post_localSupportList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SupportActionTypes.post_localSupportList.SUCCESS:
            return {
                ...state,
                loading: false,
                localSupportList: payload,
            };
        case SupportActionTypes.post_localSupportList.FAILURE:
            return {
                ...state,
                loading: false,
            };
      
        case SupportActionTypes.reset_localData.SUCCESS:
            return {
                ...state,
                localSupportList: null
            }
        case SupportActionTypes.reset_globalData.SUCCESS:
            return {
                ...state,
                globalSupportList: null
            }
        default:
            return state;
    }
};
