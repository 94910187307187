import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, user, key, ...rest }) => {
  let redirectPath = '/auth/error-page';
  if (user && rest.path !== '*') {
    redirectPath = '/auth/login-page';
  }
  return (
    <Route
      {...rest}
      key={key}
      render={(props) =>
        user
          ? <Redirect to={{ pathname: redirectPath }} />
          : <Component user={user} {...props} />}
    />
  )
}

export default PublicRoute;
