import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';

import { PostAprailsalListAction } from './actions/AppraisalActions';
import DeleteAppraisalModal from './Modal/DeleteAppraisalModal';

class AppraisalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            appraisalId: 0,
            appraisalDetails: '',
            openDeleteAppraisalModal: false
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        const data = {
            "paydate":"2022"
        }
        this.props.PostAprailsalListAction(data);
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.appraisalList !== undefined && this.props.appraisalList !== null && this.props.appraisalList !== prevProps.appraisalList) {
            this.setState({
                data: this.props.appraisalList
            })
        }
    }

    handleDeleteAppraisal = (event, rowData) => {
       let appraisalId = rowData.id
       this.setState({
        appraisalId: appraisalId,
        appraisalDetails: rowData,
        openDeleteAppraisalModal: true
    })
    }

    handleClose = () => {
        this.setState({
            appraisalId: 0,
            appraisalDetails: '',
            openDeleteAppraisalModal: false
        })
    }

    render() {
        const { loading } = this.props;
        const { columns, data, openDeleteAppraisalModal, appraisalId, appraisalDetails } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openDeleteAppraisalModal && <DeleteAppraisalModal appraisalId={appraisalId} appraisalDetails={appraisalDetails} handleClose={() => this.handleClose()}/>}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Appraisal List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode'},
                                        { title: 'Name', field: 'fullname'},
                                        { title: 'Mobile No', field: 'mobile'},
                                        { 
                                            title: 'Appraisal File',
                                            field: 'link',
                                            render: rowData => <span onClick={() => window.open(rowData.link)} style={{ cursor: "pointer", color: "blue"}}>Download File</span>
                                        },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete Appraisal Details',
                                                disabled: false,
                                                onClick: (event, rowData) => this.handleDeleteAppraisal(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

AppraisalList = reduxForm({
    form: 'AppraisalListForm',
    enableReinitialize: true
})(AppraisalList)

function mapStateToProps(state) {
    return {
        loading: state.appraisalReducer.loading,
        appraisalList: state.appraisalReducer.appraisalList !== null && state.appraisalReducer.appraisalList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostAprailsalListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AppraisalList))
