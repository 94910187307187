import { OnboaridngActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedDoc: null,
    userList: null,
    userDelete: null,
    userBlock: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case OnboaridngActionTypes.post_uploadUser.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case OnboaridngActionTypes.post_uploadUser.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedDoc: payload,
            };
        case OnboaridngActionTypes.post_uploadUser.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case OnboaridngActionTypes.get_userList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case OnboaridngActionTypes.get_userList.SUCCESS:
            return {
                ...state,
                loading: false,
                userList: payload,
            };
        case OnboaridngActionTypes.get_userList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case OnboaridngActionTypes.post_userDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case OnboaridngActionTypes.post_userDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                userDelete: payload,
            };
        case OnboaridngActionTypes.post_userDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case OnboaridngActionTypes.post_userBlock.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case OnboaridngActionTypes.post_userBlock.SUCCESS:
            return {
                ...state,
                loading: false,
                userBlock: payload,
            };
        case OnboaridngActionTypes.post_userBlock.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case OnboaridngActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
