/**
 @desc
 * Centralized unique action types for Login actions and reducers.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_LOGIN_API = `${API_URL}/admin/login`;
export const GET_REFRESH_TOKEN_API = `${API_URL}/admin/refreshtoken`;
export const POST_CHANGE_PASSWORD_API = `${API_URL}/admin/changepassword`;

export const LoginActionTypes = {
    post_Login: createRequestActionTypes('POST_LOGIN'),
    get_RefreshTokenApi: createRequestActionTypes('GET_REFRESH_TOKEN_API'),
    post_ChangePasswordApi: createRequestActionTypes('POST_CHANGE_PASSWORD_API')
};