const validate = (values) => {
    const errors = {};
    if (!values.orderId) {
        errors.orderId = 'Order Required';
    }
    if (!values.epfText) {
        errors.epfText = 'Text Required';
    }
    if (!values.redirectURL) {
        errors.redirectURL = 'URL Required';
    }
    return errors;
};
export default validate;