import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change, reset } from "redux-form";

import { config } from 'aws-sdk/lib/core';
import aws from 'aws-sdk/clients/s3';
import uuidv1 from 'uuid/v1';

import { withStyles } from '@material-ui/core/styles';
import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js"; 
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';

import { renderTextField, renderFileUpload } from "../../../shared/reduxFields";
import { showErrorMessage, csvRegex } from '../../../shared/utility';
import { ResetErrorDataAction, PostUploadAppraisalBulkAction } from './actions/AppraisalActions';


class BulkAppraisalUploadForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingDoc: false,
            isEnableUpload: false,
            isEnableSave: false,
            selectedDoc: '',
        }
    }

    onClickFileHandler = () => {
        this.setState({
            loadingDoc: false,
            isEnableUpload: false,
            isEnableSave: false,
            selectedDoc: '',
        }, () => {
            this.props.dispatch(change('BulkAppraisalUploadForm', 'uploadDoc_S3', null));
            return document.getElementById('uploadDoc_S3').value = null;
        })
    }

    clearHandler = () => {
        this.setState({
            loadingDoc: false,
            isEnableUpload: false,
            isEnableSave: false,
            selectedDoc: '',
        }, () => {
            this.props.dispatch(reset('BulkAppraisalUploadForm'));
            return document.getElementById('uploadExcel').value = null;
        })
    }

    onChangeHandler = (event) => {
        let file = event.target.files[0];
        if (file !== undefined) {
            if (!file) {
                this.props.ResetErrorDataAction();
            }
            if (csvRegex.exec(file.name)) {
                this.setState({
                    selectedDoc: file,
                    isEnableUpload: true
                }, () => {
                    this.props.ResetErrorDataAction();
                    this.props.dispatch(change('BulkAppraisalUploadForm', 'uploadDoc_S3', null));

                })
            } else {
                showErrorMessage("File type not supported");
                this.setState({
                    selectedDoc: '',
                    isEnableUpload: false
                }, () => {
                    this.props.dispatch(change('BulkAppraisalUploadForm', 'uploadDoc_S3', null));
                })
            }
        }
    }

    onUploadClickHandler = () => {
        config.update({
            region: process.env.REACT_APP_S3_REGION_CONTENT,
            accessKeyId: process.env.REACT_APP_S3_ACCESSKEY_CONTENT,
            secretAccessKey: process.env.REACT_APP_S3_ACCESSSECRET_CONTENT
        });

        const s3bucket = new aws();
        if (this.state.isEnableUpload) {
            this.setState({
                loadingDoc: true
            }, () => {
                const params = {
                    Key: uuidv1(),
                    ContentType: this.state.selectedDoc.type,
                    Body: this.state.selectedDoc,
                    Bucket: process.env.REACT_APP_S3_BUCKET_CONTENT,
                    ACL: 'public-read',
                }
                s3bucket.upload(params, (err, data) => {
                    if (data && !err) {
                        this.setState({
                            loadingDoc: false,
                            isEnableUpload: false,
                            isEnableSave: true,
                            selectedDoc: '',
                        }, () => {
                            this.props.dispatch(change('BulkAppraisalUploadForm', 'uploadDoc_S3', data.Location))
                        })
                    } else {
                        this.setState({
                            loadingDoc: false,
                            isEnableUpload: false,
                            isEnableSave: false,
                            selectedDoc: '',
                        })
                    }
                })
            })
        }
    }

    downloadTemplatehandler = () => {
        window.open('https://hr-app-jamsfy.s3.amazonaws.com/appraisaladd.csv')
    }

    handlerClearHandler = () => {
        this.props.dispatch(reset('BulkAppraisalUploadForm'));
        this.setState({
            isEnableUpload: false,
            isEnableSave: false
        })
        if (document.getElementById("uploadExcel").value !== null) {
            document.getElementById("uploadExcel").value = null;
        }
    }

    onFormSubmitHandler = (formData) => {
       if(formData.uploadDoc_S3 !== null) {
           const postData = {
               "filelink": formData.uploadDoc_S3,
               "year":"2022"
           }
           this.props.dispatch(PostUploadAppraisalBulkAction(postData));
           this.setState({
               loadingDoc: false,
               isEnableUpload: false,
               isEnableSave: false,
               selectedDoc: '',
           }, () => {
               this.props.dispatch(reset('BulkAppraisalUploadForm'));
               return document.getElementById('uploadExcel').value = null;
           })
       } else {
           showErrorMessage('Please select a valid file')
       }
    }

    render() {
        const { loading, handleSubmit } = this.props;
        const { loadingDoc, isEnableSave, isEnableUpload } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>User Onboarding</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Button link onClick={() => this.downloadTemplatehandler()}>
                                                Click Here to Download Sample Template
                                            </Button>
                                        </GridItem>
                                        <GridItem md={9} xs={12}>
                                            <Field
                                                id="uploadExcel"
                                                name="uploadExcel"
                                                label="Select File"
                                                required={true}
                                                onClick={() => this.onClickFileHandler()}
                                                onChange={(e) => this.onChangeHandler(e)}
                                                component={renderFileUpload}
                                            />
                                        </GridItem>
                                        <GridItem md={3} xs={12}>
                                            <Button
                                                disabled={!isEnableUpload || loadingDoc}
                                                color="rose"
                                                onClick={() => this.onUploadClickHandler()}
                                            >
                                                {!isEnableSave ? "Upload" : "Uploaded"}
                                                {loadingDoc && <CircularProgress size={25} />}
                                            </Button>
                                        </GridItem>
                                        <GridItem md={12} xs={12} hidden>
                                            <Field
                                                id="uploadDoc_S3"
                                                name="uploadDoc_S3"
                                                disabled="true"
                                                required="true"
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Button type="submit" color="rose">
                                                SUBMIT
                                            </Button>
                                            <Button onClick={() => this.clearHandler()}>
                                                CLEAR
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

BulkAppraisalUploadForm = reduxForm({
    form: 'BulkAppraisalUploadForm',
    enableReinitialize: true
})(BulkAppraisalUploadForm)

function mapStateToProps(state) {
    return {
        loading: state.onboardingReducer.loading, 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            ResetErrorDataAction,
            PostUploadAppraisalBulkAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BulkAppraisalUploadForm))
