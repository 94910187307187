/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_UPLOAD_BANNER_API = `${API_URL}/admin/banner/add`;
export const POST_UPDATE_BANNER_API = `${API_URL}/admin/banner/update`;
export const GET_BANNER_LIST_API = `${API_URL}/admin/banner/list`;
export const GET_BANNER_DETAIL_API = `${API_URL}/admin/banner/detail`;
export const POST_BANNER_DELETE_API = `${API_URL}/admin/banner/delete`;

export const BannerActionTypes = {
    post_uploadBanner: createRequestActionTypes('POST_UPLOAD_BANNER'),
    post_updateBanner: createRequestActionTypes('POST_UPDATE_BANNER'),
    get_bannerList: createRequestActionTypes('GET_BANNER_LIST'),
    get_bannerDetail: createRequestActionTypes('GET_BANNER_DETAIL'),
    post_bannerDelete: createRequestActionTypes('POST_BANNER_DELETE'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};