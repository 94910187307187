import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, reset } from "redux-form";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper, MenuItem } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import Button from "components/CustomButtons/Button.js";

import { PostAttendanceListAction } from './actions/AttendanceActions';
import DeleteAttendanceModal from './Modal/DeleteAttendanceModal';
import { renderSelectField } from "../../../shared/reduxFields";
import validate from './AttendanceListValidate';

const monthValues = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
]

const yearValues = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
]

class AttendanceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            attendaceId: 0,
            attendanceDetails: '',
            openDeleteAttendanceModal: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.attendanceList !== undefined && this.props.attendanceList !== null && this.props.attendanceList !== prevProps.attendanceList) {
            this.setState({
                data: this.props.attendanceList
            })
        }
    }

    handleDeleteattendance = (event, rowData) => {
        let attendaceId = rowData.id
        this.setState({
            attendaceId: attendaceId,
            attendanceDetails: rowData,
            openDeleteAttendanceModal: true
        })
    }

    handleClose = () => {
        this.setState({
            attendaceId: 0,
            attendanceDetails: '',
            openDeleteAttendanceModal: false
        })
    }

    clearHandler = () => {
        this.props.dispatch(reset('AttendanceListForm'));
    }

    onFormSubmitHandler = (formData) => {
        let payload = {
            "paydate": `${formData.month}/${formData.year}`
        } 
        this.props.PostAttendanceListAction(payload);
    }

    render() {
        const { loading, handleSubmit } = this.props;
        const { columns, data, openDeleteAttendanceModal, attendaceId, attendanceDetails } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openDeleteAttendanceModal && <DeleteAttendanceModal attendaceId={attendaceId} attendanceDetails={attendanceDetails} handleClose={() => this.handleClose()} />}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Attendance List</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                    <GridContainer>
                                        <GridItem xs={4}>
                                            <Field
                                                id="month"
                                                name="month"
                                                label="Select Month"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    monthValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Field
                                                id="year"
                                                name="year"
                                                label="Select Year"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    yearValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Button type="submit" color="rose">
                                                SUBMIT
                                            </Button>
                                            <Button onClick={() => this.clearHandler()}>
                                                CLEAR
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                                <MaterialTable
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode' },
                                        { title: 'Attendance Date', field: 'attendancedate' },
                                        { title: 'Abs', field: 'abs' },
                                        { title: 'DP', field: 'dp' },
                                        { title: 'LWP', field: 'lwp' },
                                        { title: 'WO', field: 'wo' },
                                        { title: 'WOP', field: 'wop' },
                                        { title: 'CL', field: 'cl' },
                                        { title: 'CO+', field: 'co_plus' },
                                        { title: 'CO-', field: 'co_minus' },
                                        { title: 'OD', field: 'od' },
                                        { title: 'PL', field: 'pl' },
                                        { title: 'PH', field: 'ph' },
                                        { title: 'PHP', field: 'php' },
                                        { title: 'L1', field: 'l1' },
                                        { title: 'SL', field: 'sl' },
                                        { title: 'L2', field: 'l2' },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            // {
                                            //     icon: Refresh,
                                            //     tooltip: 'Refresh List',
                                            //     isFreeAction: true,
                                            //     onClick: () => this.loadData()
                                            // },
                                            // rowData => ({
                                            //     icon: Delete,
                                            //     tooltip: 'Delete Attendance Details',
                                            //     disabled: false,
                                            //     onClick: (event, rowData) => this.handleDeleteAttendance(event, rowData)
                                            // })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

AttendanceList = reduxForm({
    form: 'AttendanceListForm',
    validate,
    enableReinitialize: true
})(AttendanceList)

function mapStateToProps(state) {
    return {
        loading: state.attendanceReducer.loading,
        attendanceList: state.attendanceReducer.attendanceList !== null && state.attendanceReducer.attendanceList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostAttendanceListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AttendanceList))
