
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import Dashboard from '../../views/Modules/Dashboard/Dashboard';
import SupportList from '../../views/Modules/Support/SupportList';

var adminRoutes = [
    {
        collapse: true,
        name: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                mini: "DSB",
                component: Dashboard,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Support",
        icon: ContactMailIcon,
        state: "supportCollapse",
        views: [
            {
                path: "/support",
                name: "Support List",
                mini: "SPL",
                component: SupportList,
                layout: "/admin"
            }
        ]
    },
];
export default adminRoutes;
