import { BannerActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedBanner: null,
    bannerList: null,
    updateBanner: null,
    deletedBanner: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case BannerActionTypes.post_uploadBanner.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BannerActionTypes.post_uploadBanner.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedBanner: payload,
            };
        case BannerActionTypes.post_uploadBanner.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case BannerActionTypes.get_bannerList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BannerActionTypes.get_bannerList.SUCCESS:
            return {
                ...state,
                loading: false,
                bannerList: payload,
            };
        case BannerActionTypes.get_bannerList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case BannerActionTypes.post_bannerDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BannerActionTypes.post_bannerDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedBanner: payload,
            };
        case BannerActionTypes.post_bannerDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case BannerActionTypes.post_updateBanner.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BannerActionTypes.post_updateBanner.SUCCESS:
            return {
                ...state,
                loading: false,
                updateBanner: payload,
            };
        case BannerActionTypes.post_updateBanner.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case BannerActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
