import { LoginActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    loggedInUserDetails: null,
    isLoggedIn: false,
    passwordChanged: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LoginActionTypes.post_Login.REQUEST:
            return {
                ...state,
                loading: true,
                loggedInUserDetails: null,
                isLoggedIn: false
            };
        case LoginActionTypes.post_Login.SUCCESS:
            return {
                ...state,
                loading: false,
                loggedInUserDetails: payload,
                isLoggedIn: true
            };
        case LoginActionTypes.post_Login.FAILURE:
            return {
                ...state,
                loading: false,
                isLoggedIn: false
            };
        case LoginActionTypes.get_RefreshTokenApi.REQUEST:
            return {
                ...state,
                loading: true,
                isLoggedIn: false
            }
        case LoginActionTypes.get_RefreshTokenApi.SUCCESS:
            return {
                ...state,
                loading: false,
                loggedInUserDetails: payload,
                isLoggedIn: true
            }
        case LoginActionTypes.get_RefreshTokenApi.FAILURE:
            return {
                ...state,
                loading: false,
                loggedInUserDetails: null,
                isLoggedIn: false
            }
        case LoginActionTypes.post_ChangePasswordApi.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case LoginActionTypes.post_ChangePasswordApi.SUCCESS:
            return {
                ...state,
                loading: false,
                passwordChanged: payload,
            }
        case LoginActionTypes.post_ChangePasswordApi.FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};
