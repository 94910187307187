/**
 * @desc: Generic components for the inputs like textFields, Checkboxes, RadionButtons, Dropdowns.
 */
import React from "react";
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from 'react-select';
// import AsyncSelect from "react-select/async";
// import Select from "react-select";
import {
  DatePicker
} from '@material-ui/pickers';

import Aux from "./hoc/Auxiliary/Auxiliary";



export const renderPassword = ({
  input,
  required,
  id,
  name,
  label,
  type,
  margin,
  inputProps,
  onChange,
  fullWidth,
  value,
  helperText,
  disabled,
  multiline,
  rows,
  onKeyPress,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <Aux>
      <TextField
        required={required}
        fullWidth
        margin="dense"
        rows={rows}
        id={id}
        name={name}
        label={label}
        type="password"
        variant="outlined"
        multiline={multiline}
        disabled={disabled ? true : false}
        inputProps={inputProps ? inputProps : {}}
        onChange={event => onChange}
        onKeyPress={onKeyPress}
        value={value ? value : ""}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </Aux>
  );
};


export const renderTextField = ({
  input,
  required,
  id,
  name,
  label,
  type,
  margin,
  inputProps,
  onChange,
  fullWidth,
  value,
  helperText,
  disabled,
  multiline,
  rows,
  onKeyPress,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <Aux>
      <TextField
        type={type}
        required={required}
        fullWidth
        margin="dense"
        rows={rows}
        id={id}
        name={name}
        label={label}
        multiline={multiline}
        variant="outlined"
        className="inputTxtCap"
        disabled={disabled ? true : false}
        inputProps={inputProps ? inputProps : {}}
        onChange={event => onChange}
        onKeyPress={onKeyPress}
        value={value ? value : ""}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </Aux>
  );
};


export const renderFileUpload = ({
  input,
  required,
  id,
  name,
  label,
  onChange,
  onClick,
  key,
  inputProps,
  autoFocus,
  meta: { touched, error },
  disabled,
  ...custom
}) => {
  return (
    <TextField
      required={required}
      id={id}
      name={name}
      label={label}
      fullWidth
      autoFocus={autoFocus}
      inputProps={inputProps ? inputProps : {}}
      margin="dense"
      type="file"
      variant="outlined"
      className="inputFileUpload"
      error={touched && error ? true : false}
      helperText={touched && error}
      onClick={onClick}
      disabled={disabled}
      onChange={e => input.onChange(e)}
    />
  );
};

export const renderSelectField = ({
  input,
  required,
  id,
  name,
  label,
  type,
  errorText,
  margin,
  inputProps,
  onChange,
  fullWidth,
  value,
  helperText,
  customError,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <Aux>
      <TextField
        select
        fullWidth
        margin="dense"
        required={required}
        id={id}
        className="inputTxtCap selectinput"
        name={name}
        label={label}
        variant="outlined"
        inputProps={inputProps ? inputProps : {}}
        onChange={event => onChange}
        value={value}
        error={touched && error ? true : false}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </Aux>
  );
};

export const renderRadioGroup = ({ input, value, ...rest }) => {
  return (
    <RadioGroup
      {...input}
      {...rest}
      aria-label="position"
      row
      value={value}
      onChange={(e, value) => input.onChange(value)}
    />
  );
};

export const renderCheckBox = ({
  input,
  label,
  checked,
  data,
  color,
  disabled,
  meta: { touched, error },
  custom
}) => {
  return (
    <Aux>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            {...custom}
            value={data}
            // color="#EF8132"
            color={color}
            disabled={disabled}
            checked={data === true ? true : false}
            onChange={(e, value) => input.onChange(value)}
          />
        }
        label={label}
      />
    </Aux>
  );
};


//Please do not use this for now
export const renderMonthYearPicker = ({ input, label, value, meta: { touched, error }, ...custom }) => {
  return (
    <DatePicker
      {...input}
      {...custom}
      onChange={val => input.onChange(val)}
      views={["year", "month", "day"]}
      label="Card Expiry"
      minDate={moment()}
      inputVariant="outlined"
      disablePast={true}
      value={value}
      format="MM/YYYY"
    />
  );
};

export const renderDatePicker = ({ input, label, value, disabled, maxDate, minDate, meta: { touched, error }, ...custom }) => {
  return (
    <DatePicker
      color="rose"
      variant="outlined"
      inputVariant="outlined"
      label={label}
      required={true}
      minDate={minDate}
      maxDate={maxDate}
      margin="dense"
      disabled={disabled}
      disableFuture={true}
      fullWidth
      value={input.value ? input.value : null}
      onChange={event => input.onChange(event)}
      format='DD/MM/YYYY'
      error={touched && error ? true : false}
      helperText={touched && error}
    />
  )
}

//new

export const renderDropdown = ({ input, onBlur, id, label, name, options, meta: { touched, error }, ...props }) => {

  const handleBlur = e => e.preventDefault();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid transparent',
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: '4px',
      '&:hover': { borderColor: 'black' }, // border style on hover
      border: '2px solid lightgray', // default border color
      boxShadow: 'none', // no box-shadow
      fontSize: '1rem',
      fontWeight: 400
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }


  return (
    <React.Fragment>
      <Select {...input}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#c1c1c133',
            primary: 'black',
          },
        })}
        maxMenuHeight="80px"
        id={id}
        name={name}
        placeholder={`${label} *`}
        options={options}
        onChange={e => input.onChange(e)}
        onBlur={handleBlur}
        isSearchable={true}
      />
    </React.Fragment>
  )
}