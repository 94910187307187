import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk'
import { Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import './index.css';
import RootReducer from './reducers';
import Routes from './routers/routesComponent';
import history from './shared/history';

const store = createStore(
  RootReducer,
  applyMiddleware(reduxThunk)
);

const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#EF8132',
        },
        '&$active': {
          color: '#EF8132',
        },
      },
      active: {},
      completed: {},
    },
  },
  palette: {
    primary: {
      main: '#4A4E92',
    },
    secondary: {
      main: '#EF8132',
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }

});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
          <Alert stack={{ limit: 3 }} />
        </Router>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
