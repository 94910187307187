
/**
 @desc
 * Centralized unique actions for Payslip Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    SupportActionTypes,
    POST_SUPPORT_LIST_GLOBAL_API,
    POST_SUPPORT_LIST_LOCAL_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostGlobalSupportListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SupportActionTypes.post_globalSupportList.REQUEST));
        fetch(`${POST_SUPPORT_LIST_GLOBAL_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SupportActionTypes.post_globalSupportList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SupportActionTypes.post_globalSupportList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SupportActionTypes.post_globalSupportList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SupportActionTypes.post_globalSupportList.FAILURE));
                console.log('error post_globalSupportList ..', error);
            });
    };
};

export const PostLocalSupportListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SupportActionTypes.post_localSupportList.REQUEST));
        fetch(`${POST_SUPPORT_LIST_LOCAL_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SupportActionTypes.post_localSupportList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SupportActionTypes.post_localSupportList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SupportActionTypes.post_localSupportList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SupportActionTypes.post_localSupportList.FAILURE));
                console.log('error post_localSupportList ..', error);
            });
    };
};

export const ResetGlobalSupportDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(SupportActionTypes.reset_globalData.SUCCESS, null));
    }
}
export const ResetLocalSupportDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(SupportActionTypes.reset_localData.SUCCESS, null));
    }
}