
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import Dashboard from '../../views/Modules/Dashboard/Dashboard';

import BulkUserUpload from 'views/Modules/Onboarding/BulkUserUpload';
import UserList from 'views/Modules/Onboarding/UserList';

import BannerUpload from '../../views/Modules/Banner/BannerUpload';
import BannerList from '../../views/Modules/Banner/BannerList';
import BulkAppraisalUpload from 'views/Modules/Appraisal/BulkAppraisalUpload';
import AppraisalList from '../../views/Modules/Appraisal/AppraisalList';

import PayslipUpload from '../../views/Modules/Payslip/PayslipUpload';
import PayslipList from '../../views/Modules/Payslip/PayslipList';

import MasterCTCUpload from '../../views/Modules/MasterCTC/MasterCTCUpload';
import MasterCTCList from '../../views/Modules/MasterCTC/MasterCTCList';

import AttendanceUpload from '../../views/Modules/Attendance/AttendanceUpload';
import AttendanceList from '../../views/Modules/Attendance/AttendanceList';

import EpfList from '../../views/Modules/EPF/EPFList';

var adminRoutes = [
    {
        collapse: true,
        name: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                mini: "DSB",
                component: Dashboard,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Onboarding",
        icon: PeopleAltIcon,
        state: "onboardingCollapse",
        views: [
            {
                path: "/useronboarding",
                name: "User Onboarding",
                mini: "UOB",
                component: BulkUserUpload,
                layout: "/admin"
            },
            {
                path: "/userlist",
                name: "User List",
                mini: "UL",
                component: UserList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Master CTC",
        icon: AccountBalanceIcon,
        state: "masterCtcCollapse",
        views: [
            {
                path: "/mctcupload",
                name: "Master CTC Upload",
                mini: "MCP",
                component: MasterCTCUpload,
                layout: "/admin"
            },
            {
                path: "/mctclist",
                name: "Master CTC List",
                mini: "MCL",
                component: MasterCTCList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Payslip",
        icon: ReceiptIcon,
        state: "payslipCollapse",
        views: [
            {
                path: "/payslipupload",
                name: "Payslip Upload",
                mini: "PSU",
                component: PayslipUpload,
                layout: "/admin"
            },
            {
                path: "/paysliplist",
                name: "Payslip List",
                mini: "PL",
                component: PayslipList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Attendance",
        icon: DateRangeIcon,
        state: "attendanceCollapse",
        views: [
            {
                path: "/attendanceupload",
                name: "Attendance Upload",
                mini: "PSU",
                component: AttendanceUpload,
                layout: "/admin"
            },
            {
                path: "/attendancelist",
                name: "Attendance List",
                mini: "PL",
                component: AttendanceList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Appraisal",
        icon: LocalPrintshopIcon,
        state: "appraisalCollapse",
        views: [
            {
                path: "/appraisalUpload",
                name: "Upload Appraisal",
                mini: "UA",
                component: BulkAppraisalUpload,
                layout: "/admin"
            },
            {
                path: "/appraisalList",
                name: "Appraisal List",
                mini: "AL",
                component: AppraisalList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Banner",
        icon: BrandingWatermarkIcon,
        state: "bannerCollapse",
        views: [
            {
                path: "/bannerupload",
                name: "Upload Banner",
                mini: "UOB",
                component: BannerUpload,
                layout: "/admin"
            },
            {
                path: "/bannerList",
                name: "Banner List",
                mini: "UL",
                component: BannerList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "EPF",
        icon: AccountBalanceWalletIcon,
        state: "epfCollapse",
        views: [
            {
                path: "/epflist",
                name: "EPF List",
                mini: "EL",
                component: EpfList,
                layout: "/admin"
            },
        ]
    }
];
export default adminRoutes;
