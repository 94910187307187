import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, reset } from "redux-form";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper, MenuItem } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import Button from "components/CustomButtons/Button.js";

import { PostGlobalSupportListAction, PostLocalSupportListAction, ResetGlobalSupportDataAction, ResetLocalSupportDataAction } from './actions/SupportActions';
import { renderSelectField } from "../../../shared/reduxFields";
import validate from './SupportListValidate';

const monthValues = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
]

const yearValues = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' }
]

const supportType = [
    { value: 'LOCAL', label: 'LOCAL' },
    { value: 'GLOBAL', label: 'GLOBAL' }
]

class SupportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            supportId: 0,
            supportDetails: '',
            suppportType: '',
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.globalSupportList !== undefined && this.props.globalSupportList !== null && this.props.globalSupportList !== prevProps.globalSupportList) {
            this.setState({
                data: this.props.globalSupportList
            })
        }
        if (this.props.localSupportList !== undefined && this.props.localSupportList !== null && this.props.localSupportList !== prevProps.localSupportList) {
            this.setState({
                data: this.props.localSupportList
            })
        }
    }

    handleDeleteSupport = (event, rowData) => {
        let supportId = rowData.id
        this.setState({
            supportId: supportId,
            supportDetails: rowData,
        })
    }

    handleClose = () => {
        this.setState({
            supportId: 0,
            supportDetails: '',
        })
    }

    clearHandler = () => {
        this.props.dispatch(reset('SupportListForm'));
    }

    supportTypeChangeHandler = (event) => {
        this.setState({
            suppportType: event.target.value
        })
    } 

    onFormSubmitHandler = async(formData) => {
        let payload = {
            "paydate": `${formData.month}/${formData.year}`
        } 
        if(this.state.suppportType === "GLOBAL") {
            await this.props.ResetLocalSupportDataAction();
            await this.props.PostGlobalSupportListAction(payload);
        } else {
            await this.props.ResetGlobalSupportDataAction();
            await this.props.PostLocalSupportListAction(payload);
        }
    }

    render() {
        const { loading, handleSubmit } = this.props;
        const { data, supportId, supportDetails } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Support List</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                    <GridContainer>
                                        <GridItem xs={3}>
                                            <Field
                                                id="month"
                                                name="month"
                                                label="Select Month"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    monthValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <Field
                                                id="year"
                                                name="year"
                                                label="Select Year"
                                                required={true}
                                                component={renderSelectField}
                                            >
                                                {
                                                    yearValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <Field
                                                id="type"
                                                name="type"
                                                label="Select Type"
                                                required={true}
                                                onChange={(e) => this.supportTypeChangeHandler(e)}
                                                component={renderSelectField}
                                            >
                                                {
                                                    supportType.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={3}>
                                            <Button type="submit" color="rose">
                                                SUBMIT
                                            </Button>
                                            <Button onClick={() => this.clearHandler()}>
                                                CLEAR
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                                <MaterialTable
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode' },
                                        { title: 'Name', field: 'fullname' },
                                        { title: 'Mobile No', field: 'mobile' },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            // {
                                            //     icon: Refresh,
                                            //     tooltip: 'Refresh List',
                                            //     isFreeAction: true,
                                            //     onClick: () => this.loadData()
                                            // },
                                            // rowData => ({
                                            //     icon: Delete,
                                            //     tooltip: 'Delete Support Details',
                                            //     disabled: false,
                                            //     onClick: (event, rowData) => this.handleDeleteSupport(event, rowData)
                                            // })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

SupportList = reduxForm({
    form: 'SupportListForm',
    validate,
    enableReinitialize: true
})(SupportList)

function mapStateToProps(state) {
    return {
        loading: state.supportReducer.loading,
        globalSupportList: state.supportReducer.globalSupportList !== null && state.supportReducer.globalSupportList.data,
        localSupportList: state.supportReducer.localSupportList !== null && state.supportReducer.localSupportList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostGlobalSupportListAction,
            PostLocalSupportListAction,
            ResetGlobalSupportDataAction,
            ResetLocalSupportDataAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SupportList))
