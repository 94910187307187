import { EPFActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createdEPF: null,
    epfList: null,
    updateEPF: null,
    deletedEPF: null,
    epfDetails: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case EPFActionTypes.post_createEPF.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EPFActionTypes.post_createEPF.SUCCESS:
            return {
                ...state,
                loading: false,
                createdEPF: payload,
            };
        case EPFActionTypes.post_createEPF.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EPFActionTypes.get_epfList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EPFActionTypes.get_epfList.SUCCESS:
            return {
                ...state,
                loading: false,
                epfList: payload,
            };
        case EPFActionTypes.get_epfList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EPFActionTypes.post_epfDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EPFActionTypes.post_epfDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedEPF: payload,
            };
        case EPFActionTypes.post_epfDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EPFActionTypes.post_updateEPF.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EPFActionTypes.post_updateEPF.SUCCESS:
            return {
                ...state,
                loading: false,
                updateEPF: payload,
            };
        case EPFActionTypes.post_updateEPF.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EPFActionTypes.get_epfDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EPFActionTypes.get_epfDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                epfDetails: payload,
            };
        case EPFActionTypes.get_epfDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case EPFActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
