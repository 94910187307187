
/**
 @desc
 * Centralized unique actions for Payslip Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    PayslipActionTypes,
    POST_UPLOAD_PAYSLIP_API,
    POST_PAYSLIP_LIST_API,
    POST_PAYSLIP_DELETE_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";



export const PostUploadPayslipAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(PayslipActionTypes.post_uploadPayslip.REQUEST));
        fetch(`${POST_UPLOAD_PAYSLIP_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(PayslipActionTypes.post_uploadPayslip.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(PayslipActionTypes.post_uploadPayslip.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(PayslipActionTypes.post_uploadPayslip.SUCCESS, response));
                    dispatch(reset('PayslipUploadForm'));
                    showSuccessMessage('File is under processing!! In case of error an email will be sent to your email ID')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(PayslipActionTypes.post_uploadPayslip.FAILURE));
                console.log('error post_uploadPayslip ..', error);
            });
    };
};

export const PostPayslipListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(PayslipActionTypes.post_payslipList.REQUEST));
        fetch(`${POST_PAYSLIP_LIST_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(PayslipActionTypes.post_payslipList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(PayslipActionTypes.post_payslipList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(PayslipActionTypes.post_payslipList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(PayslipActionTypes.post_payslipList.FAILURE));
                console.log('error post_payslipList ..', error);
            });
    };
};

export const PostPayslipDeleteAction = (payslipId, payload) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(PayslipActionTypes.post_payslipDelete.REQUEST));
        fetch(`${POST_PAYSLIP_DELETE_API}/${payslipId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(PayslipActionTypes.post_payslipDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(PayslipActionTypes.post_payslipDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(PayslipActionTypes.post_payslipDelete.SUCCESS, response));
                    dispatch(PostPayslipListAction(payload));
                    showSuccessMessage('Payslip Deleted Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(PayslipActionTypes.post_payslipDelete.FAILURE));
                console.log('error post_payslipDelete ..', error);
            });
    };
};



export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(PayslipActionTypes.reset_errorData.SUCCESS, null));
    }
}