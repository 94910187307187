import { AppraisalActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedBulkAppraisalFile: null,
    appraisalList: null,
    appraisalDelete: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case AppraisalActionTypes.post_uploadBulkAppraisal.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AppraisalActionTypes.post_uploadBulkAppraisal.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedBulkAppraisalFile: payload,
            };
        case AppraisalActionTypes.post_uploadBulkAppraisal.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AppraisalActionTypes.post_appraisalList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AppraisalActionTypes.post_appraisalList.SUCCESS:
            return {
                ...state,
                loading: false,
                appraisalList: payload,
            };
        case AppraisalActionTypes.post_appraisalList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AppraisalActionTypes.post_appraisalDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AppraisalActionTypes.post_appraisalDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                appraisalDelete: payload,
            };
        case AppraisalActionTypes.post_appraisalDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AppraisalActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
