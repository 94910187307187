import { PayslipActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedPayslipFile: null,
    payslipList: null,
    payslipDelete: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case PayslipActionTypes.post_uploadPayslip.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PayslipActionTypes.post_uploadPayslip.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedPayslipFile: payload,
            };
        case PayslipActionTypes.post_uploadPayslip.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case PayslipActionTypes.post_payslipList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PayslipActionTypes.post_payslipList.SUCCESS:
            return {
                ...state,
                loading: false,
                payslipList: payload,
            };
        case PayslipActionTypes.post_payslipList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case PayslipActionTypes.post_payslipDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PayslipActionTypes.post_payslipDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                payslipDelete: payload,
            };
        case PayslipActionTypes.post_payslipDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case PayslipActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
