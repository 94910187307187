import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh, Add } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import { GetEPFListAction } from './actions/EPFActions';
import EditEPFModal from './Modal/CreateEditEPFModal';
import DeleteEPFModal from './Modal/DeleteEPFModal';


class EPFList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            epfId: 0,
            openCreateEditEPFModal: false,
            epfDetails: '',
            openDeleteEPFModal: false,
            type: ''
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetEPFListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.epfList !== undefined && this.props.epfList !== null && this.props.epfList !== prevProps.epfList) {
            this.setState({
                data: this.props.epfList
            })
        }
    }

    handleEditEPFDetails = (event, rowData) => {
        let epfId = rowData.id;
        this.setState({
            epfId: epfId,
            epfDetails: rowData,
            openCreateEditEPFModal: true,
            type: 'Edit'
        })
    }

    handleDeleteEPF = (event, rowData) => {
        let epfId = rowData.id;
        this.setState({
            epfId: epfId,
            epfDetails: rowData,
            openDeleteEPFModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openCreateEditEPFModal: false,
            openDeleteEPFModal: false,
            epfId: 0,
            epfDetails: '',
            type: ''
        })
    }

    addEPFDetailsHandler = () => {
        this.setState({
            openCreateEditEPFModal: true,
            type: 'Add'
        })
    }

    render() {
        const { loading } = this.props;
        const { 
            data, openCreateEditEPFModal, openDeleteEPFModal, 
            epfId, epfDetails, type
        } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openCreateEditEPFModal && <EditEPFModal epfId={epfId} type={type} epfDetails={epfDetails} handleClose={() => this.handleClose()}/>}
                {openDeleteEPFModal && <DeleteEPFModal epfId={epfId} epfDetails={epfDetails} handleClose={() => this.handleClose()}/>}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>EPF List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        {
                                            title: 'Text', 
                                            field: 'text', 
                                        },
                                        {
                                            title: 'Link', 
                                            field: 'link', 
                                            render: rowData => <a href={rowData.link}> {rowData.link} </a>
                                        },
                                        { 
                                            title: 'Status', 
                                            field: 'isactive', 
                                            render: rowData => rowData.isactive == "Y" 
                                            ? <span style={{ color: 'green' }}><b>Active</b></span> 
                                            : <span style={{ color: 'red' }}><b>In-Active</b></span>
                                        },
                                        { 
                                            title: 'Order', 
                                            field: 'sortorder', 
                                            filtering: false, 
                                            render: rowData => <b>{rowData.sortorder}</b>
                                        },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                            {
                                                icon: Add,
                                                tooltip: 'Add EPF Details',
                                                isFreeAction: true,
                                                onClick: () => this.addEPFDetailsHandler()
                                            },
                                            {
                                                icon: Edit,
                                                tooltip: 'Chnage EPF Details',
                                                onClick: (event, rowData) => this.handleEditEPFDetails(event, rowData)
                                            },
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete EPF',
                                                onClick: (event, rowData) => this.handleDeleteEPF(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

EPFList = reduxForm({
    form: 'EPFListForm',
    enableReinitialize: true
})(EPFList)

function mapStateToProps(state) {
    return {
        loading: state.epfReducer.loading,
        epfList: state.epfReducer.epfList !== null && state.epfReducer.epfList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetEPFListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(EPFList))
