/**
 @desc
 * Centralized unique action types for Appraisal module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_UPLOAD_BULK_APPRAISAL_API = `${API_URL}/admin/bulk/appraisal/add`;
export const POST_APPRAISAL_LIST_API = `${API_URL}/admin/appraisal/detail`;
export const POST_APPRAISAL_DELETE_API = `${API_URL}/admin/appraisal/delete`;

export const AppraisalActionTypes = {
    post_uploadBulkAppraisal: createRequestActionTypes('POST_UPLOAD_BULK_APPRAISAL'),
    post_appraisalList: createRequestActionTypes('POST_APPRAISAL_LIST'),
    post_appraisalDelete: createRequestActionTypes('POST_APPRAISAL_DELETE'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};