import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

import { withStyles } from "@material-ui/core/styles";

import UserIcon from "@material-ui/icons/AccountCircleOutlined";
import Password from '@material-ui/icons/LockOutlined';

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Fingerprint from "@material-ui/icons/Fingerprint";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { renderTextField } from "../../shared/reduxFields";
import { LoginUserAction } from './actions/LoginActions';
import ForgotPassword from './ForgotPassword/forgotPassword';
import validate from './loginValidate';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openForgotPasword: false
    };
  }

  onFormSubmitHandler = (formData) => {
    let postData = {
      "email": formData.email,
      "password": formData.password
    }
    this.props.LoginUserAction(postData)
  }

  handleOpen = () => {
    this.setState({ openForgotPasword: true });
  };

  handleClose = () => {
    this.setState({ openForgotPasword: false });
  };

  render() {
    const { classes, handleSubmit, loading } = this.props;
    const { openForgotPasword } = this.state;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
              <Card login>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>LOG IN</h4>
                </CardHeader>
                <CardBody>
                  <Field
                    id="email"
                    name="email"
                    label="email"
                    required={true}
                    component={renderTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <UserIcon className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />

                  <Field
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    required={true}
                    component={renderTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Password className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" type="submit" disabled={loading}>
                    <Fingerprint className={classes.listItemIcon} />
                    {loading ? 'LOGGING IN ...' : 'LOG IN'}
                  </Button>

                </CardFooter>
                {/* <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" link onClick={() => this.handleOpen()}>
                    FORGOT PASSWORD
                </Button>
                </CardFooter> */}
              </Card>
            </form>
            {
              openForgotPasword &&
              <ForgotPassword
                handleClose={() => this.handleClose()}
              />

            }
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

Login = reduxForm({
  form: "LoginForm",
  validate,
  enableReinitialize: true
})(Login);


function mapStateToProps(state) {
  return {
    loading: state.loginReducer.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({
      LoginUserAction
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
