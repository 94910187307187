import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, reset } from "redux-form";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper, MenuItem } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import Button from "components/CustomButtons/Button.js";

import { PostMasterCTCListAction } from './actions/MasterCTCActions';
import DeleteMasterCTCModal from './Modal/MasterCTCModal';
import { renderSelectField } from "../../../shared/reduxFields";
import validate from './MasterCTCListValidate';

const yearValues = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' }
]

class MasterCTCList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            masterctcId: 0,
            masterCTCDetails: '',
            year: '',
            openDeleteMasterCTCModal: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.masterCTCList !== undefined && this.props.masterCTCList !== null && this.props.masterCTCList !== prevProps.masterCTCList) {
            this.setState({
                data: this.props.masterCTCList
            })
        }
    }

    handleDeleteMasterCTC = (event, rowData) => {
        let masterctcId = rowData.id
        this.setState({
            masterctcId: masterctcId,
            masterCTCDetails: rowData,
            openDeleteMasterCTCModal: true
        })
    }

    handleClose = () => {
        this.setState({
            masterctcId: 0,
            masterCTCDetails: '',
            openDeleteMasterCTCModal: false
        })
    }

    clearHandler = () => {
        this.props.dispatch(reset('MasterCTCListForm'));
    }

    onFormSubmitHandler = (formData) => {
        let payload = {
            "payyear": `${formData.year}`
        } 
        this.props.PostMasterCTCListAction(payload);
    }

    yearChangeHandler = (e) => {
        this.setState({
            year: e.target.value
        })
    }

    render() {
        const { loading, handleSubmit } = this.props;
        const { columns, data, openDeleteMasterCTCModal, masterctcId, masterCTCDetails, year} = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openDeleteMasterCTCModal && <DeleteMasterCTCModal year={year} masterctcId={masterctcId} masterCTCDetails={masterCTCDetails} handleClose={() => this.handleClose()} />}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Master CTC List</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                    <GridContainer>
                                        <GridItem xs={4}>
                                            <Field
                                                id="year"
                                                name="year"
                                                label="Select Year"
                                                required={true}
                                                onChange={(e) => this.yearChangeHandler(e)}
                                                component={renderSelectField}
                                            >
                                                {
                                                    yearValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Button type="submit" color="rose">
                                                SUBMIT
                                            </Button>
                                            <Button onClick={() => this.clearHandler()}>
                                                CLEAR
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                                <MaterialTable
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode' },
                                        { title: 'From Date', field: 'from_date' },
                                        { title: 'To Date', field: 'to_date' },
                                        { title: 'Basic', field: 'basic' },
                                        { title: 'HRA', field: 'hra' },
                                        { title: 'Special Allowance', field: 'special_allowance' },
                                        { title: 'Gross For OT', field: 'gross_for_ot' },
                                        { title: 'Aten Inc CTC Monthly', field: 'atten_inc_ctc_mnth' },
                                        { title: 'DC Allowance', field: 'dc_allowance' },
                                        { title: 'Relocation Allowance', field: 'relocation_allowance' },
                                        { title: 'Gross Earnings CTC', field: 'gross_earnings_ctc' },
                                        { title: 'Gross Corp Reim', field: 'gross_corporate_reim' },
                                        { title: 'Gross Attire Reim', field: 'gross_attire_reim' },
                                        { title: 'Gross Vehicle Reim', field: 'gross_vehicle_reim' },
                                        { title: 'Gross Food Reim', field: 'gross_food_reim' },
                                        { title: 'Gross Petrol Reim', field: 'gross_petrol_reim' },
                                        { title: 'Gross Driver Reim', field: 'gross_driver_reim' },
                                        { title: 'Gross Books Reim', field: 'gross_books_reim' },
                                        { title: 'Gross Mobile Reim', field: 'gross_mobile_reim' },
                                        { title: 'Gross Peiodic Reim', field: 'gross_periodic_reim' },
                                        { title: 'Monthly Total Reim', field: 'monthly_total_reim' },
                                        { title: 'PF', field: 'prov_fund' },
                                        { title: 'ESIC Emp CTC', field: 'esic_emp_ctc' },
                                        { title: 'PT Emp CTC', field: 'pt_ctc_mnth' },
                                        { title: 'Total Deduction CTC', field: 'tot_ded_ctc' },
                                        { title: 'Net Take Home CTC', field: 'net_take_home_ctc' },
                                        { title: 'Exgratia', field: 'exgratia' },
                                        { title: 'Bonus CTC month', field: 'bonus_ctc_mnth' },
                                        { title: 'Total Comp Contribution', field: 'tot_comp_contrib' },
                                        { title: 'Qtr Leave Calc', field: 'qtr_leave_calc' },
                                        { title: 'Company Pf Contri', field: 'company_pf_contri' },
                                        { title: 'ESIC Cmp CTC', field: 'esic_cmp_ctc' },
                                        { title: 'Graturity CTC Month', field: 'gratutity_ctc_mnth' },
                                        { title: 'Total Company Contri', field: 'total_company_contri' },
                                        { title: 'Monthly CTC', field: 'monthly_ctc' },
                                        { title: 'Monthly CTC Reim', field: 'mnthly_ctc_with_reim' },
                                        { title: 'PLI CTC', field: 'pli_ctc' },
                                        { title: 'Annual CTC Reim', field: 'annual_ctc_with_reim' },
                                        { title: 'Status', field: 'status' },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        grouping: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            // {
                                            //     icon: Refresh,
                                            //     tooltip: 'Refresh List',
                                            //     isFreeAction: true,
                                            //     onClick: () => this.loadData()
                                            // },
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete Master CTC Details',
                                                disabled: false,
                                                onClick: (event, rowData) => this.handleDeleteMasterCTC(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

MasterCTCList = reduxForm({
    form: 'MasterCTCListForm',
    validate,
    enableReinitialize: true
})(MasterCTCList)

function mapStateToProps(state) {
    return {
        loading: state.masterCTCReducer.loading,
        masterCTCList: state.masterCTCReducer.masterCTCList !== null && state.masterCTCReducer.masterCTCList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostMasterCTCListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MasterCTCList))
