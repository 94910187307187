import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Switch } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@material-ui/core/styles";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import PrivateRoute from '../routers/PrivateRoutes/PrivateRoute';
import adminRoutes from '../routers/RolebasedRoutes/adminRoutes';
import checkerRoutes from '../routers/RolebasedRoutes/CheckerRoutes';
import makerRoutes from '../routers/RolebasedRoutes/MakerRoutes';
import supportRoutes from '../routers/RolebasedRoutes/SupportRoutes';

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import wellnessLogo from '../assets/img/WFLogo.png';

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });

  const mainPanel = React.createRef();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const role = useSelector(state => state.loginReducer.loggedInUserDetails.data.role)

  const handleImageClick = image => {
    setImage(image);
  };

  const handleColorClick = color => {
    setColor(color);
  };

  const handleBgColorClick = bgColor => {
    // switch (bgColor) {
    //   case "white":
    //     setLogo(require("assets/img/WFLogo.png"));
    //     break;
    //   default:
    //     setLogo(require("assets/img/WFLogo.png"));
    //     break;
    // }
    setBgColor(bgColor);
  };

  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              user={role}
            />
          );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const defineRole = (role) => {
    if (role.toUpperCase() === "ADMIN") {
      return getRoutes(adminRoutes)
    } else if (role.toUpperCase() === "MAKER") {
      return getRoutes(makerRoutes)
    } else if (role.toUpperCase() === "CHECKER") {
      return getRoutes(checkerRoutes)
    } else if (role.toUpperCase() === "SUPPORT") {
      return getRoutes(supportRoutes)
    } else {
      return;
    }
  }

  const drawerMenueItems = (role) => {
    if(role.toUpperCase() === "ADMIN") {
      return adminRoutes;
    } else if(role.toUpperCase() === "MAKER") {
      return makerRoutes;
    } else if(role.toUpperCase() === "CHECKER") {
      return checkerRoutes;
    } else if(role.toUpperCase() === "SUPPORT") {
      return supportRoutes;
    } else {
      return;
    }
  }

  const activeRouteHandler = (role) => {
    if(role.toUpperCase() === "ADMIN") {
      return getActiveRoute(adminRoutes)
    } else if(role.toUpperCase() === "MAKER") {
      return getActiveRoute(makerRoutes);
    } else if(role.toUpperCase() === "CHECKER") {
      return getActiveRoute(checkerRoutes);
    } else if(role.toUpperCase() === "SUPPORT") {
      return getActiveRoute(supportRoutes);
    } else {
      return;
    }
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={drawerMenueItems(role)}
        logoText={"HR PORTAL"}
        logo={wellnessLogo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={activeRouteHandler(role)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {defineRole(role)}
              </Switch>
            </div>
          </div>
        ) : null }
        {getRoute() ? <Footer fluid /> : null}
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        />
      </div>
    </div>
  );
}
