import { UamActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    userList: null,
    updatedUser: null,
    blockedUnblockedUserById: null,
    createdUser: null,
    rolesList: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case UamActionTypes.get_AllUsers.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UamActionTypes.get_AllUsers.SUCCESS:
            return {
                ...state,
                loading: false,
                userList: payload,
            };
        case UamActionTypes.get_AllUsers.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case UamActionTypes.post_CreateUser.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UamActionTypes.post_CreateUser.SUCCESS:
            return {
                ...state,
                loading: false,
                createdUser: payload,
            }
        case UamActionTypes.post_CreateUser.FAILURE:
            return {
                ...state,
                loading: false
            }
        case UamActionTypes.post_UpdateUser.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UamActionTypes.post_UpdateUser.SUCCESS:
            return {
                ...state,
                loading: false,
                updatedUser: payload,
            }
        case UamActionTypes.post_UpdateUser.FAILURE:
            return {
                ...state,
                loading: false,
            }
        case UamActionTypes.post_BlockUnblockUser.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UamActionTypes.post_BlockUnblockUser.SUCCESS:
            return {
                ...state,
                loading: false,
                blockedUnblockedUserById: payload
            }
        case UamActionTypes.post_BlockUnblockUser.FAILURE:
            return {
                ...state,
                loading: false,
            }

        case UamActionTypes.get_AllRoles.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UamActionTypes.get_AllRoles.SUCCESS:
            return {
                ...state,
                loading: false,
                rolesList: payload,
            };
        case UamActionTypes.get_AllRoles.FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
