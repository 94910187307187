/**
 @desc
 * Centralized unique action types for Payslip module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_SUPPORT_LIST_GLOBAL_API = `${API_URL}/admin/global_support/list`;
export const POST_SUPPORT_LIST_LOCAL_API = `${API_URL}/admin/local_support/list`;

export const SupportActionTypes = {
    post_globalSupportList: createRequestActionTypes('POST_SUPPORT_LIST_GLOBAL'),
    post_localSupportList: createRequestActionTypes('POST_SUPPORT_LIST_LOCAL'),
    reset_globalData: createRequestActionTypes('RESET_GLOBAL_DATA'),
    reset_localData: createRequestActionTypes('RESET_LOCAL_DATA'),
};