
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    BannerActionTypes,
    POST_UPLOAD_BANNER_API,
    GET_BANNER_LIST_API,
    POST_UPDATE_BANNER_API,
    POST_BANNER_DELETE_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostBannerUploadAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(BannerActionTypes.post_uploadBanner.REQUEST));
        fetch(`${POST_UPLOAD_BANNER_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(BannerActionTypes.post_uploadBanner.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(BannerActionTypes.post_uploadBanner.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(BannerActionTypes.post_uploadBanner.SUCCESS, response));
                    dispatch(reset('BannerUploadForm'));
                    showSuccessMessage('Banner Uploaded Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(BannerActionTypes.post_uploadBanner.FAILURE));
                console.log('error post_uploadBanner ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(BannerActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetBannerListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(BannerActionTypes.get_bannerList.REQUEST));
        fetch(`${GET_BANNER_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(BannerActionTypes.get_bannerList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(BannerActionTypes.get_bannerList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(BannerActionTypes.get_bannerList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(BannerActionTypes.get_bannerList.FAILURE));
                console.log('error get_bannerList ..', error);
            });
    };
};

export const PostBannerUpdateAction = (postData, bannerId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(BannerActionTypes.post_updateBanner.REQUEST));
        fetch(`${POST_UPDATE_BANNER_API}/${bannerId}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(BannerActionTypes.post_updateBanner.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(BannerActionTypes.post_updateBanner.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(BannerActionTypes.post_updateBanner.SUCCESS, response));
                    showSuccessMessage('Banner Updated Successfully');
                    dispatch(GetBannerListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(BannerActionTypes.post_updateBanner.FAILURE));
                console.log('error post_updateBanner ..', error);
            });
    };
};

export const PostBannerDeleteAction = (postData, bannerId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(BannerActionTypes.post_bannerDelete.REQUEST));
        fetch(`${POST_BANNER_DELETE_API}/${bannerId}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(BannerActionTypes.post_bannerDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(BannerActionTypes.post_bannerDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(BannerActionTypes.post_bannerDelete.SUCCESS, response));
                    showSuccessMessage('Banner Deleted Successfully');
                    dispatch(GetBannerListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(BannerActionTypes.post_bannerDelete.FAILURE));
                console.log('error post_bannerDelete ..', error);
            });
    };
};

