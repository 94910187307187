import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PostBannerUpdateAction } from '../actions/BannerActions';
import { renderTextField, renderSelectField } from "../../../../shared/reduxFields";
import validate from './BannerEditModalValidate';

const orderValues = [
    { Key: 1, value: 1},
    { Key: 2, value: 2},
    { Key: 3, value: 3},
    { Key: 4, value: 4},
    { Key: 5, value: 5},
    { Key: 6, value: 6},
    { Key: 7, value: 7},
    { Key: 8, value: 8},
]

class EditBannerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        this.props.dispatch(change('EditBannerModalForm', 'orderId', this.props.orderId))
        this.props.dispatch(change('EditBannerModalForm', 'redirectURL', this.props.bannerDetails.href))
    }

    onFormSubmitHandler = (formData) => {
        const bannerDetails = this.props.bannerDetails
        const bannerId = bannerDetails.id
        const postData = {
            "image": bannerDetails.image,
            "sortorder": formData.orderId,
            "href": formData.redirectURL,
            "isactive": bannerDetails.isactive
        }
        this.props.PostBannerUpdateAction(postData, bannerId);
        this.props.handleClose();
    }

    orderChnageHandler = (event) => {
        let orderId = event.target.value
    }

    render() {
        const { classes, handleSubmit } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>Edit Banner Order</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="orderId"
                                                name="orderId"
                                                label="Select Order"
                                                required={true}
                                                onChange={(e) => this.orderChnageHandler(e)}
                                                component={renderSelectField}
                                            >
                                                {
                                                    orderValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.value}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="redirectURL"
                                                name="redirectURL"
                                                label="Redirect URL"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

EditBannerModal = reduxForm({
    form: "EditBannerModalForm",
    validate,
    enableReinitialize: true
})(EditBannerModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostBannerUpdateAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(EditBannerModal));
