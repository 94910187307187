
/**
 @desc
 * Centralized unique actions for Payslip Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    MasterCTCActionTypes,
    POST_UPLOAD_MASTER_CTC_API,
    POST_MASTER_CTC_LIST_API,
    POST_MASTER_CTC_DELETE_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";



export const PostUploadMasterCTCAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(MasterCTCActionTypes.post_uploadMasterCTC.REQUEST));
        fetch(`${POST_UPLOAD_MASTER_CTC_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(MasterCTCActionTypes.post_uploadMasterCTC.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(MasterCTCActionTypes.post_uploadMasterCTC.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(MasterCTCActionTypes.post_uploadMasterCTC.SUCCESS, response));
                    dispatch(reset('PayslipUploadForm'));
                    showSuccessMessage('File is under processing!! In case of error an email will be sent to your email ID')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(MasterCTCActionTypes.post_uploadMasterCTC.FAILURE));
                console.log('error post_uploadMasterCTC ..', error);
            });
    };
};

export const PostMasterCTCListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCList.REQUEST));
        fetch(`${POST_MASTER_CTC_LIST_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCList.FAILURE));
                console.log('error post_masterCTCList ..', error);
            });
    };
};

export const PostMasterCTCDeleteAction = (payslipId, payload) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCDelete.REQUEST));
        fetch(`${POST_MASTER_CTC_DELETE_API}/${payslipId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCDelete.SUCCESS, response));
                    dispatch(PostMasterCTCListAction(payload));
                    showSuccessMessage('Master CTC Deleted Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(MasterCTCActionTypes.post_masterCTCDelete.FAILURE));
                console.log('error post_masterCTCDelete ..', error);
            });
    };
};



export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(MasterCTCActionTypes.reset_errorData.SUCCESS, null));
    }
}