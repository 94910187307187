import React, { Component } from 'react'
import ImgsViewer from 'react-images-viewer'

export default class ImageViewer extends Component {
    render() {
        return (
            <div>
                <ImgsViewer
                    imgs={this.props.imgsArray}
                    isOpen={this.props.isOpen}
                    onClose={this.props.closeImgsViewer}
                />
            </div>
        )
    }
}
