
/**
 @desc
 * Centralized unique actions for Onboarding Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    OnboaridngActionTypes,
    POST_UPLOAD_USER_API,
    GET_USER_LIST_API,
    POST_USER_DELETE_API,
    POST_USER_BLOCK_UNBLOCK_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";



export const PostUploadUserBulkAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(OnboaridngActionTypes.post_uploadUser.REQUEST));
        fetch(`${POST_UPLOAD_USER_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(OnboaridngActionTypes.post_uploadUser.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(OnboaridngActionTypes.post_uploadUser.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(OnboaridngActionTypes.post_uploadUser.SUCCESS, response));
                    dispatch(reset('BulkUserUploadForm'));
                    showSuccessMessage('File is under processing!! In case of error an email will be sent to your email ID')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(OnboaridngActionTypes.post_uploadUser.FAILURE));
                console.log('error post_uploadUser ..', error);
            });
    };
};



export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(OnboaridngActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetUserListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(OnboaridngActionTypes.get_userList.REQUEST));
        fetch(`${GET_USER_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(OnboaridngActionTypes.get_userList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(OnboaridngActionTypes.get_userList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(OnboaridngActionTypes.get_userList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(OnboaridngActionTypes.get_userList.FAILURE));
                console.log('error get_userList ..', error);
            });
    };
};

export const PostUserDeleteAction = (userId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(OnboaridngActionTypes.post_userDelete.REQUEST));
        fetch(`${POST_USER_DELETE_API}/${userId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(OnboaridngActionTypes.post_userDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(OnboaridngActionTypes.post_userDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(OnboaridngActionTypes.post_userDelete.SUCCESS, response));
                    showSuccessMessage('User Deleted Successfully');
                    dispatch(GetUserListAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(OnboaridngActionTypes.post_userDelete.FAILURE));
                console.log('error post_userDelete ..', error);
            });
    };
};

export const PostUserBlockUnblockAction = (postData, userId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(OnboaridngActionTypes.post_userBlock.REQUEST));
        fetch(`${POST_USER_BLOCK_UNBLOCK_API}/${userId}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(OnboaridngActionTypes.post_userBlock.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(OnboaridngActionTypes.post_userBlock.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(OnboaridngActionTypes.post_userBlock.SUCCESS, response));
                    showSuccessMessage('User Status Updated');
                    dispatch(GetUserListAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(OnboaridngActionTypes.post_userBlock.FAILURE));
                console.log('error post_userBlock ..', error);
            });
    };
};