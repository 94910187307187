import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'; // SAYING use redux form reducers as reducers

import loginReducer from '../views/Login/reducers/LoginReducers';
import uamReducer from '../views/Modules/UAM/reducers/UamReducers';
import onboardingReducer from '../views/Modules/Onboarding/reducers/OnboardingReducers';
import bannerreducer from '../views/Modules/Banner/reducers/BannerReducers';
import appraisalReducer from  '../views/Modules/Appraisal/reducers/AppraisalReducers';
import payslipReducer from '../views/Modules/Payslip/reducers/PayslipReducers';
import attendanceReducer from '../views/Modules/Attendance/reducers/AttendanceReducers';
import masterCTCReducer from '../views/Modules/MasterCTC/reducers/MasterCTCReducers';
import UamReducer from '../views/Modules/UAM/reducers/UamReducers';
import supportReducer from '../views/Modules/Support/reducers/SupportReducers';
import EPFReducers from '../views/Modules/EPF/reducers/EPFReducers';

export default combineReducers({
    form: formReducer,
    loginReducer: loginReducer,
    uamReducer: uamReducer,
    onboardingReducer: onboardingReducer,
    bannerreducer: bannerreducer,
    appraisalReducer: appraisalReducer,
    payslipReducer: payslipReducer,
    attendanceReducer: attendanceReducer,
    masterCTCReducer: masterCTCReducer,
    uamReducer: UamReducer,
    supportReducer: supportReducer,
    epfReducer: EPFReducers
})