import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import { GetBannerListAction } from './actions/BannerActions';
import ImageViewer from '../../../shared/ImageViewer';
import EditBannerModal from './Modal/BannerEditModal';
import DeactivateBannerModal from './Modal/DeactivateBannerModal';
import DeleteBannerModal from './Modal/DeleteBannerModal';


class BannerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            bannerId: 0,
            orderId: 0,
            openImageViewer: false,
            imgsArray: [],
            openEditBannerModal: false,
            bannerDetails: '',
            openDeactivateBannerModal: false,
            openDeleteBannerModal: false
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetBannerListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.bannerList !== undefined && this.props.bannerList !== null && this.props.bannerList !== prevProps.bannerList) {
            this.setState({
                data: this.props.bannerList
            })
        }
    }

    ImageClickHandler = (rowData) => {
        let ImageArr = []
        let ImgObj = {}
        ImgObj['src'] =  rowData.image
        ImageArr.push(ImgObj)
        this.setState({
            openImageViewer: true,
            imgsArray: ImageArr
        })
    }

    closeImageViewer = () => {
        this.setState({
            openImageViewer: false,
            imgsArray: []
        })
    }

    handleEditBannerDetails = (event, rowData) => {
        let bannerId = rowData.id;
        this.setState({
            bannerId: bannerId,
            orderId: rowData.sortorder,
            bannerDetails: rowData,
            openEditBannerModal: true
        })
    }

    handleDeleteBanner = (event, rowData) => {
        let bannerId = rowData.id;
        this.setState({
            bannerId: bannerId,
            bannerDetails: rowData,
            openDeleteBannerModal: true
        })
    }

    handleDeactivateBanner = (event, rowData) => {
        let bannerId = rowData.id;
        this.setState({
            bannerId: bannerId,
            bannerDetails: rowData,
            openDeactivateBannerModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openEditBannerModal: false,
            openDeactivateBannerModal: false,
            openDeleteBannerModal: false,
            bannerId: 0,
            orderId: 0,
            bannerDetails: ''
        })
    }

    render() {
        const { loading } = this.props;
        const { 
            data, openEditBannerModal, openDeactivateBannerModal, openDeleteBannerModal, 
            bannerId, orderId, bannerDetails, imgsArray, openImageViewer 
        } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openEditBannerModal && <EditBannerModal bannerId={bannerId} orderId={orderId} bannerDetails={bannerDetails} handleClose={() => this.handleClose()}/>}
                {openDeactivateBannerModal && <DeactivateBannerModal bannerId={bannerId} bannerDetails={bannerDetails} handleClose={() => this.handleClose()}/>}
                {openDeleteBannerModal && <DeleteBannerModal bannerId={bannerId} bannerDetails={bannerDetails} handleClose={() => this.handleClose()}/>}
                {openImageViewer && <ImageViewer imgsArray={imgsArray} isOpen={openImageViewer} closeImgsViewer={() => this.closeImageViewer()}/>}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Banner List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { 
                                            title: 'Image', 
                                            field: 'Image',
                                            filtering: false, 
                                            render: rowData => <img src={rowData.image} style={{ width: 200, borderRadius: '10%', cursor: 'pointer' }} alt="banner" onClick={() => this.ImageClickHandler(rowData)}/> 
                                        },
                                        { 
                                            title: 'Status', 
                                            field: 'isactive', 
                                            render: rowData => rowData.isactive == "Y" 
                                            ? <span style={{ color: 'green' }}><b>Active</b></span> 
                                            : <span style={{ color: 'red' }}><b>In-Active</b></span>
                                        },
                                        { 
                                            title: 'Order', 
                                            field: 'sortorder', 
                                            filtering: false, 
                                            render: rowData => <b>{rowData.sortorder}</b>
                                        },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                            rowData => ({
                                                icon: Edit,
                                                tooltip: 'Chnage Banner Order',
                                                disabled: rowData.isactive == "N" ? true : false,
                                                onClick: (event, rowData) => this.handleEditBannerDetails(event, rowData)
                                            }),
                                            rowData => ({
                                                icon: BlockIcon,
                                                tooltip:  rowData.isactive == "N" ? 'Activate Banner' : "Deactivate Banner",
                                                disabled: false,
                                                onClick: (event, rowData) => this.handleDeactivateBanner(event, rowData)
                                            }),
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete Banner',
                                                disabled: rowData.isactive == "Y" ? true : false,
                                                onClick: (event, rowData) => this.handleDeleteBanner(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

BannerList = reduxForm({
    form: 'BannerListForm',
    enableReinitialize: true
})(BannerList)

function mapStateToProps(state) {
    return {
        loading: state.bannerreducer.loading,
        bannerList: state.bannerreducer.bannerList !== null && state.bannerreducer.bannerList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetBannerListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BannerList))
