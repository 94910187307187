import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, reset } from "redux-form";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper, MenuItem } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Loader from '../../../components/Loader/index';
import Button from "components/CustomButtons/Button.js";

import { PostPayslipListAction } from './actions/PayslipActions';
import DeletePayslipModal from './Modal/DeletePayslipModal';
import { renderSelectField } from "../../../shared/reduxFields";
import validate from './PayslipListValidate';

const monthValues = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
]

const yearValues = [
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' }
]

class PayslipList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            payslipId: 0,
            payslipDetails: '',
            year: '',
            month: '',
            openDeletePayslipModal: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.payslipList !== undefined && this.props.payslipList !== null && this.props.payslipList !== prevProps.payslipList) {
            this.setState({
                data: this.props.payslipList
            })
        }
    }

    handleDeletePayslip = (event, rowData) => {
        let payslipId = rowData.id
        this.setState({
            payslipId: payslipId,
            payslipDetails: rowData,
            openDeletePayslipModal: true
        })
    }

    handleClose = () => {
        this.setState({
            payslipId: 0,
            payslipDetails: '',
            openDeletePayslipModal: false
        })
    }

    clearHandler = () => {
        this.props.dispatch(reset('PayslipListForm'));
    }

    onFormSubmitHandler = (formData) => {
        let payload = {
            "paydate": `${formData.month}/${formData.year}`
        } 
        this.props.PostPayslipListAction(payload);
    }

    yearChangeHandler = (e) => {
        this.setState({
            year: e.target.value
        })
    }

    monthChangeHandler = (e) => {
        this.setState({
            month: e.target.value
        })
    }

    render() {
        const { loading, handleSubmit } = this.props;
        const { columns, data, openDeletePayslipModal, payslipId, payslipDetails, year, month } = this.state;

        return (
            <>
                {loading && <Loader open={true} loaderMessage="Fetching..." />}
                {openDeletePayslipModal && <DeletePayslipModal year={year} month={month} payslipId={payslipId} payslipDetails={payslipDetails} handleClose={() => this.handleClose()} />}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Payslip List</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                    <GridContainer>
                                        <GridItem xs={4}>
                                            <Field
                                                id="month"
                                                name="month"
                                                label="Select Month"
                                                required={true}
                                                component={renderSelectField}
                                                onChange={(e) => this.monthChangeHandler(e)}
                                            >
                                                {
                                                    monthValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Field
                                                id="year"
                                                name="year"
                                                label="Select Year"
                                                required={true}
                                                onChange={(e) => this.yearChangeHandler(e)}
                                                component={renderSelectField}
                                            >
                                                {
                                                    yearValues.map((obj, index) => {
                                                        return <MenuItem value={obj.value} key={index}>{obj.label}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Button type="submit" color="rose">
                                                SUBMIT
                                            </Button>
                                            <Button onClick={() => this.clearHandler()}>
                                                CLEAR
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                                <MaterialTable
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { title: 'Employee ID', field: 'empcode' },
                                        { title: 'Gross OT', field: 'gross_ot' },
                                        { title: 'Basic', field: 'basic' },
                                        { title: 'HRA', field: 'hra' },
                                        { title: 'Conveyance', field: 'conveyance' },
                                        { title: 'Education Allow', field: 'education_allow' },
                                        { title: 'Medical', field: 'mediacl' },
                                        { title: 'Special Allow', field: 'special_allow' },
                                        { title: 'DC Allow', field: 'dc_allow' },
                                        { title: 'Attend Inc', field: 'attend_inc' },
                                        { title: 'Neg', field: 'neg' },
                                        { title: 'Statu Bonus', field: 'statu_bonus' },
                                        { title: 'Exgratia', field: 'exgratia' },
                                        { title: 'Leave Quarterly', field: 'leave_quaterly' },
                                        { title: 'Bonus', field: 'bonus' },
                                        { title: 'Night Allow', field: 'night_allow' },
                                        { title: 'Other Allow', field: 'other_allow' },
                                        { title: 'Weekly Inc', field: 'weekly_inc' },
                                        { title: 'Outstation Allow', field: 'outstation_allow' },
                                        { title: 'Comp Allow', field: 'comp_allow' },
                                        { title: 'Relocation Allow', field: 'reloaction_allow' },
                                        { title: 'Indi Incentive', field: 'indi_incentive' },
                                        { title: 'Store Incentive', field: 'store_incentive' },
                                        { title: 'Trading INC', field: 'trading_inc' },
                                        { title: 'Leave Encashment', field: 'leave_encashment' },
                                        { title: 'Target Incentive', field: 'target_incentive' },
                                        { title: 'Project Incentive', field: 'project_incentive' },
                                        { title: 'Referral Inc', field: 'referral_inc' },
                                        { title: 'Audit Inc', field: 'audit_incentive' },
                                        { title: 'Travel Allow', field: 'travel_allow' },
                                        { title: 'Stores Selection Inc', field: 'stores_selection_inc' },
                                        { title: 'Breakeven Incentive', field: 'breakeven_incentive' },
                                        { title: 'Quarter Inc', field: 'quarter_inc' },
                                        { title: 'Quarter PL Inc', field: 'quarter_pl_inc' },
                                        { title: 'Megh Malhar Inc', field: 'megh_malhar_inc' },
                                        { title: 'Amor Pl Sales Inc', field: 'amor_pl_sales_inc' },
                                        { title: 'Face Mask Inc', field: 'face_mask_inc' },
                                        { title: 'Corporate Inc', field: 'corporate_reim' },
                                        { title: 'Prof Attire Expense', field: 'prof_attire_expense' },
                                        { title: 'Drivers Salary', field: 'drivers_salary' },
                                        { title: 'Food Reim', field: 'food_reim' },
                                        { title: 'Petrol Reim', field: 'petrol_reim' },
                                        { title: 'vehicle Reim', field: 'vehicle_reim' },
                                        { title: 'Mobile Reim', field: 'mobile_reim' },
                                        { title: 'Periodical Reim', field: 'periodical_reim' },
                                        { title: 'Books and Per Reim', field: 'books_and_per_reim' },
                                        { title: 'Covid Special Reward', field: 'covid_special_reward' },
                                        { title: 'Gross Earning 20per', field: 'gross_earning_20per' },
                                        { title: 'Special Incentive', field: 'special_incentive' },
                                        { title: 'Total Earning', field: 'total_earning' },
                                        { title: 'PF', field: 'prov_fund' },
                                        { title: 'ESIC', field: 'esic' },
                                        { title: 'PTax', field: 'ptax' },
                                        { title: 'MLWF EE', field: 'mlwf_ee' },
                                        { title: 'TDS', field: 'tds' },
                                        { title: 'Telephone Expense', field: 'telephone_expense' },
                                        { title: 'Unfiorm Dedu', field: 'unfiorm_ded' },
                                        { title: 'Audit Recovery', field: 'audit_recovery' },
                                        { title: 'Store Advance', field: 'store_advance' },
                                        { title: 'Miscellaneous', field: 'miscellaneous' },
                                        { title: 'Loan', field: 'loan' },
                                        { title: 'VPF', field: 'vpf' },
                                        { title: 'Mediclaim', field: 'mediclaim' },
                                        { title: 'Total Deduction', field: 'total_deduction' },
                                        { title: 'Net Paid', field: 'net_paid' },
                                        { title: 'Days Present', field: 'days_present' },
                                        { title: 'Days Absent', field: 'day_absent' },
                                        { title: 'Days Paid', field: 'day_paid' },
                                        { title: 'Abs', field: 'abs' },
                                        { title: 'DP', field: 'dp' },
                                        { title: 'LWP', field: 'lwp' },
                                        { title: 'WO', field: 'wo' },
                                        { title: 'WOP', field: 'wop' },
                                        { title: 'CL', field: 'cl' },
                                        { title: 'CO+', field: 'co_plus' },
                                        { title: 'CO-', field: 'co_minus' },
                                        { title: 'OD', field: 'od' },
                                        { title: 'PL', field: 'pl' },
                                        { title: 'PH', field: 'ph' },
                                        { title: 'PHP', field: 'php' },
                                        { title: 'SL', field: 'sl' },
                                        { title: 'L1', field: 'l1' },
                                        { title: 'L2', field: 'l2' },
                                        { title: 'Total DP', field: 'total_dp' },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                    }}
                                    actions={
                                        [
                                            // {
                                            //     icon: Refresh,
                                            //     tooltip: 'Refresh List',
                                            //     isFreeAction: true,
                                            //     onClick: () => this.loadData()
                                            // },
                                            rowData => ({
                                                icon: Delete,
                                                tooltip: 'Delete Payslip Details',
                                                disabled: false,
                                                onClick: (event, rowData) => this.handleDeletePayslip(event, rowData)
                                            })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

PayslipList = reduxForm({
    form: 'PayslipListForm',
    validate,
    enableReinitialize: true
})(PayslipList)

function mapStateToProps(state) {
    return {
        loading: state.payslipReducer.loading,
        payslipList: state.payslipReducer.payslipList !== null && state.payslipReducer.payslipList.data,
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostPayslipListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(PayslipList))
