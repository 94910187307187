
/**
 @desc
 * Centralized unique actions for EPF Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    EPFActionTypes,
    POST_CREATE_EPF_API,
    GET_EPF_LIST_API,
    POST_UPDATE_EPF_API,
    POST_EPF_DELETE_API,
    GET_EPF_DETAIL_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateEPFAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(EPFActionTypes.post_createEPF.REQUEST));
        fetch(`${POST_CREATE_EPF_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(EPFActionTypes.post_createEPF.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(EPFActionTypes.post_createEPF.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(EPFActionTypes.post_createEPF.SUCCESS, response));
                    dispatch(reset('CreateEPFForm'));
                    showSuccessMessage('EPF Created Successfully')
                    dispatch(GetEPFListAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(EPFActionTypes.post_createEPF.FAILURE));
                console.log('error post_createEPF ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(EPFActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetEPFListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(EPFActionTypes.get_epfList.REQUEST));
        fetch(`${GET_EPF_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(EPFActionTypes.get_epfList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(EPFActionTypes.get_epfList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(EPFActionTypes.get_epfList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(EPFActionTypes.get_epfList.FAILURE));
                console.log('error get_epfList ..', error);
            });
    };
};

export const GetEPFDetailsAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(EPFActionTypes.get_epfDetail.REQUEST));
        fetch(`${GET_EPF_DETAIL_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(EPFActionTypes.get_epfDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(EPFActionTypes.get_epfDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(EPFActionTypes.get_epfDetail.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(EPFActionTypes.get_epfDetail.FAILURE));
                console.log('error get_epfDetail ..', error);
            });
    };
};

export const PostEPFUpdateAction = (postData, epfId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(EPFActionTypes.post_updateEPF.REQUEST));
        fetch(`${POST_UPDATE_EPF_API}/${epfId}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(EPFActionTypes.post_updateEPF.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(EPFActionTypes.post_updateEPF.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(EPFActionTypes.post_updateEPF.SUCCESS, response));
                    showSuccessMessage('EPF Details Updated Successfully');
                    dispatch(GetEPFListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(EPFActionTypes.post_updateEPF.FAILURE));
                console.log('error post_updateEPF ..', error);
            });
    };
};

export const PostBannerDeleteAction = (epfId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(EPFActionTypes.post_epfDelete.REQUEST));
        fetch(`${POST_EPF_DELETE_API}/${epfId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(EPFActionTypes.post_epfDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(EPFActionTypes.post_epfDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(EPFActionTypes.post_epfDelete.SUCCESS, response));
                    showSuccessMessage('EPF Deleted Successfully');
                    dispatch(GetEPFListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(EPFActionTypes.post_epfDelete.FAILURE));
                console.log('error post_epfDelete ..', error);
            });
    };
};

