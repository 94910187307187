import { AttendanceActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    uploadedAttendanceFile: null,
    attendanceList: null,
    attendanceDelete: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case AttendanceActionTypes.post_uploadAttendance.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AttendanceActionTypes.post_uploadAttendance.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedAttendanceFile: payload,
            };
        case AttendanceActionTypes.post_uploadAttendance.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AttendanceActionTypes.post_attendanceList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AttendanceActionTypes.post_attendanceList.SUCCESS:
            return {
                ...state,
                loading: false,
                attendanceList: payload,
            };
        case AttendanceActionTypes.post_attendanceList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AttendanceActionTypes.post_attendanceDelete.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AttendanceActionTypes.post_attendanceDelete.SUCCESS:
            return {
                ...state,
                loading: false,
                attendanceDelete: payload,
            };
        case AttendanceActionTypes.post_attendanceDelete.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case AttendanceActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
