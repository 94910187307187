
/**
 @desc
 * Centralized unique actions for Appraisal Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    AppraisalActionTypes,
    POST_UPLOAD_BULK_APPRAISAL_API,
    POST_APPRAISAL_LIST_API,
    POST_APPRAISAL_DELETE_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";



export const PostUploadAppraisalBulkAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AppraisalActionTypes.post_uploadBulkAppraisal.REQUEST));
        fetch(`${POST_UPLOAD_BULK_APPRAISAL_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AppraisalActionTypes.post_uploadBulkAppraisal.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AppraisalActionTypes.post_uploadBulkAppraisal.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AppraisalActionTypes.post_uploadBulkAppraisal.SUCCESS, response));
                    dispatch(reset('BulkAppraisalUploadForm'));
                    showSuccessMessage('File is under processing!! In case of error an email will be sent to your email ID')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AppraisalActionTypes.post_uploadBulkAppraisal.FAILURE));
                console.log('error post_uploadBulkAppraisal ..', error);
            });
    };
};

export const PostAprailsalListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AppraisalActionTypes.post_appraisalList.REQUEST));
        fetch(`${POST_APPRAISAL_LIST_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AppraisalActionTypes.post_appraisalList.FAILURE));
                console.log('error post_appraisalList ..', error);
            });
    };
};

export const PostAppraisalDeleteAction = (appraisalId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AppraisalActionTypes.post_appraisalDelete.REQUEST));
        fetch(`${POST_APPRAISAL_DELETE_API}/${appraisalId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AppraisalActionTypes.post_appraisalDelete.SUCCESS, response));
                    const payload = {
                        "paydate":"2022"
                    }
                    dispatch(PostAprailsalListAction(payload));
                    showSuccessMessage('Appraisal Deleted Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AppraisalActionTypes.post_appraisalDelete.FAILURE));
                console.log('error post_appraisalDelete ..', error);
            });
    };
};



export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(AppraisalActionTypes.reset_errorData.SUCCESS, null));
    }
}