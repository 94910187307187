
/**
 @desc
 * Centralized unique actions for Attendance Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    AttendanceActionTypes,
    POST_UPLOAD_ATTENDANCE_API,
    POST_ATTENDANCE_LIST_API,
    POST_ATTENDANCE_DELETE_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";



export const PostUploadAttendanceAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AttendanceActionTypes.post_uploadAttendance.REQUEST));
        fetch(`${POST_UPLOAD_ATTENDANCE_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AttendanceActionTypes.post_uploadAttendance.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AttendanceActionTypes.post_uploadAttendance.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AttendanceActionTypes.post_uploadAttendance.SUCCESS, response));
                    dispatch(reset('PayslipUploadForm'));
                    showSuccessMessage('File is under processing!! In case of error an email will be sent to your email ID')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AttendanceActionTypes.post_uploadAttendance.FAILURE));
                console.log('error post_uploadAttendance ..', error);
            });
    };
};

export const PostAttendanceListAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AttendanceActionTypes.post_attendanceList.REQUEST));
        fetch(`${POST_ATTENDANCE_LIST_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AttendanceActionTypes.post_attendanceList.FAILURE));
                console.log('error post_attendanceList ..', error);
            });
    };
};

export const PostAttendanceDeleteAction = (attendanceId, payload) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(AttendanceActionTypes.post_attendanceDelete.REQUEST));
        fetch(`${POST_ATTENDANCE_DELETE_API}/${attendanceId}`, {
            method: 'POST',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceDelete.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceDelete.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(AttendanceActionTypes.post_attendanceDelete.SUCCESS, response));
                    dispatch(PostAprailsalListAction(payload));
                    showSuccessMessage('Payslip Deleted Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(AttendanceActionTypes.post_attendanceDelete.FAILURE));
                console.log('error post_attendanceDelete ..', error);
            });
    };
};



export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(AttendanceActionTypes.reset_errorData.SUCCESS, null));
    }
}