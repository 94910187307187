/**
 @desc
 * Centralized unique action types for Payslip module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_UPLOAD_PAYSLIP_API = `${API_URL}/admin/bulk/payslip/add`;
export const POST_PAYSLIP_LIST_API = `${API_URL}/admin/payslip/list`;
export const POST_PAYSLIP_DELETE_API = `${API_URL}/admin/payslip/delete`;

export const PayslipActionTypes = {
    post_uploadPayslip: createRequestActionTypes('POST_UPLOAD_PAYSLIP'),
    post_payslipList: createRequestActionTypes('POST_PAYSLIP_LIST'),
    post_payslipDelete: createRequestActionTypes('POST_PAYSLIP_DELETE'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};