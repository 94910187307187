
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

import Dashboard from '../../views/Modules/Dashboard/Dashboard';

var adminRoutes = [
    {
        collapse: true,
        name: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                mini: "DSB",
                component: Dashboard,
                layout: "/admin"
            },
        ]
    }
];
export default adminRoutes;
